import React, { useState } from "react";
import base from "../assets/Base Illustration.webp";
import heroimage from "../assets/hero image.png";
import democardimage from "../assets/Rectangle 38.png";
import arrowcircleright from "../assets/arrow-circle-right.png";
import aws from "../assets/AWS-logo-black 1.png";
import Kubernetes from "../assets/Kubernetes-logo 1.png";

const caseStudies = [
  {
    id: 1,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "Cloud cost",
    img: democardimage,
  },
  {
    id: 2,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "GCP",
    img: democardimage,
  },
  {
    id: 3,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "AWS",
    img: democardimage,
  },
];

// const categories = [
//   "All",
//   "Cloud cost",
//   "AWS",
//   "GCP",
//   "Kubernetes",
//   "Snowflake",
// ];

const CaseStudiesPage = () => {
  const [selectedCategory] = useState("All");
  const [searchQuery] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);

  const filteredStudies =
    selectedCategory === "All"
      ? caseStudies
      : caseStudies.filter((study) => study.category === selectedCategory);

  const displayedStudies = filteredStudies.filter((study) =>
    study.title?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const carouselImages = [democardimage, democardimage, democardimage]; // Replace with your images

  // const nextSlide = () => {
  //   setCurrentSlide((prev) => (prev + 1) % carouselImages.length);
  // };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  // const prevSlide = () => {
  //   setCurrentSlide((prev) =>
  //     prev === 0 ? carouselImages.length - 1 : prev - 1
  //   );
  // };

  return (
    <div className="flex flex-col items-center font-rubik">
      {/* Hero Section */}
      <div className="w-full flex flex-col font-rubik md:flex-row items-center md:max-w-6xl justify-center px-6 py-12 md:px-16 md:py-16">
        {/* Text Section */}
        <div className="text-start md:text-left space-y-4">
          <h1 className="text-2xl md:text-4xl font-bold text-[#222222]">
            Top 10 Margin-Improving FinOps KPIs: A Technical Perspective on
            Cloud Cost Optimization
          </h1>
        </div>

        {/* Hero Image (Framed) */}
        <div className="flex justify-center md:justify-end mt-4 md:w-auto  md:mt-0">
          <div>
            <img
              src={heroimage}
              alt="Hero"
              loading="lazy"effect="blur"
              className="object-contain w-full min-w-[250px] max-w-[350px] 
                       md:min-w-[300px] md:max-w-[400px] 
                       lg:min-w-[350px] lg:max-w-[450px] 
                       xl:min-w-[400px] xl:max-w-[500px] 
                       2xl:min-w-[500px] 2xl:max-w-[600px] 
                       "
            />
          </div>
        </div>
      </div>

      <div className="bg-[#02AAE2] text-white w-96 md:w-full  max-w-5xl mx-auto rounded-3xl p-6 md:p-10">
        {/* Desktop Layout (Row) / Mobile Layout (Column) */}
        <div className="flex flex-col md:flex-row items-start md:items-start gap-6">
          {/* Cloud Environment */}
          <div className=" flex flex-col text-start md:text-left">
            <h3 className="text-lg font-semibold whitespace-nowrap">
              Alchemy's Cloud Environment
            </h3>
            <div className="flex gap-3 justify-start md:justify-start mt-4">
  <div className="w-10 h-10 rounded-full overflow-hidden">
    <img src={aws} alt="AWS" loading="lazy"effect="blur" className="w-full h-full object-cover" />
  </div>
  <div className="w-10 h-10 rounded-full overflow-hidden">
    <img src={Kubernetes} alt="Kubernetes" loading="lazy"effect="blur" className="w-full h-full object-cover" />
  </div>
</div>

          </div>

          {/* Divider (Only for Large Screens) */}
          <div className="hidden md:block w-1 h-24 bg-white"></div>
          {/* Mobile Divider */}
          <div className="md:hidden w-full border-t-4 border-white my-4"></div>
          {/* Company Details */}
          <div className="flex flex-col md:flex-row  font-rubik w-full text-start md:text-left gap-6">
            <div className="flex-1 min-w-[150px]">
              <h3 className="text-lg font-semibold">Company Size</h3>
              <p className="font-medium">101-250 employees</p>
            </div>
            <div className="flex-1 min-w-[150px]">
              <h3 className="text-lg font-semibold">Industry</h3>
              <p className="font-medium">Blockchain Technology</p>
            </div>
            <div className="flex-1 min-w-[150px]">
              <h3 className="text-lg font-semibold">HQ</h3>
              <p className="font-medium">San Francisco, CA, USA</p>
            </div>
          </div>
        </div>
      </div>

      {/* paragraph section */}
      <div className="max-w-5xl mx-auto px-6 py-12 space-y-8">
        {Array(2)
          .fill(null)
          .map((_, index) => (
            <div key={index} className="space-y-4">
              <h1 className="text-2xl font-bold font-rubik text-black">
                Proud of Our Progress, Excited for What’s Ahead
              </h1>
              <p className="text-[#000] font-jakarta">
                Today, we’re proud to announce a pivotal milestone in Finout’s
                journey: our $40M Series C funding, led by Insight Partners,
                with participation from Rangle, Team8, Bold Cap1tal, and
                Maverick Investments. This moment isn’t just a testament to
                Finout’s growth but a reflection of the critical role FinOps now
                plays in modern enterprises.
              </p>
              <p className="text-[#000] font-jakarta">
                This announcement holds exceptional meaning for us at Finout
                because it represents the culmination of years of dedication,
                collaboration, and trust—not just within our team but with our
                customers, investors, and partners. Our relationship with
                Insight Partners remains a cornerstone. We’re not just building
                a strong foundation with their team, sharing our vision for
                shaping the FinOps market. After countless conversations, FinOps
                is a mutual learning experience, because clear that now was the
                right time to deepen our partnership. Together, we’re poised to
                bring FinOps to its next chapter.
              </p>
            </div>
          ))}
        <h1 className=" text-3xl font-semibold italic font-rubik text-[#363636]">
          “Cloudevolve made cost allocation so much easier. I can trust the
          numbers now, and everyone can get access to the data they need without
          any bottlenecking.”
        </h1>
        {Array(1)
          .fill(null)
          .map((_, index) => (
            <div key={index} className="space-y-4 mt-5">
              <h2 className="text-2xl font-rubik font-bold text-black">
                Proud of Our Progress, Excited for What’s Ahead{" "}
              </h2>
              <p className="text-[#000]  font-jakarta">
                Today, we’re proud to announce a pivotal milestone in Finout’s
                journey: our $40M Series C funding, led by Insight Partners,
                with participation from Pitango, Team8, Red Dot Capital, and
                Maor Investments. This moment is not just a testament to
                Finout’s growth but a reflection of the critical role FinOps now
                plays in modern enterprises. This announcement holds special
                meaning for us at Finout because it represents the culmination
                of years of dedication, collaboration, and trust—not just within
                our team but with our customers, investors, and partners. Our
                relationship with Insight Partners is a prime example. We’ve
                spent years building a strong foundation with their team,
                sharing our vision for shaping the FinOps market. After
                countless conversations, Froyo’s, and mutual learning, it became
                clear that now was the right time to deepen our partnership.
                Together, we’re poised to bring FinOps to its next chapter.
              </p>
            </div>
          ))}
      </div>
      {/* {Book demo section} */}
      <div className="block w-full relative overflow-hidden">
        {/* Background Section */}
        <div className="bg-[#02AAE2] text-white flex flex-col justify-center items-center px-6 py-12 rounded-b-3xl h-auto min-h-[300px] md:h-[450px] relative">
          {/* Text Section */}
          <div className="max-w-3xl text-center z-10 md:-mt-44 font-rubik space-y-4">
            <h1 className="text-lg md:text-3xl font-bold font-rubik leading-snug">
              Click here to learn more about how Cloudevolve can help solve your
              cloud cost challenges.
            </h1>
            <div className="flex justify-center w-full mt-6">
              <button className="flex items-center px-6 md:px-8 py-2 md:py-3 text-sm md:text-base font-rubik text-[#000000] font-semibold bg-white border border-white rounded-full hover:bg-gray-100 transition">
                <span className="mr-2">Book a Demo</span>
                <img
                  src={arrowcircleright}
                  alt="arrowcircleright"
                  loading="lazy"effect="blur"
                  className="w-4 h-4 md:w-5 md:h-5"
                />
              </button>
            </div>
          </div>
        </div>

        {/* Overlapping Wave Image */}
        <img
          src={base}
          alt="Hero Illustration"
          loading="lazy"effect="blur"
          className="absolute bottom-0 left-0 right-0 z-0 object-contain"
        />
      </div>
      {/* Case Study Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-6xl mt-6">
        {displayedStudies.map((study) => (
          <div
            key={study.id}
            className="bg-white border border-black rounded-xl overflow-hidden"
          >
            <img
              src={study.img}
              alt={study.title}
              loading="lazy"effect="blur"
              className="w-full aspect-square  h-56 object-cover"
            />
            <div className="p-4">
              <h3 className="font-semibold text-lg">{study.title}</h3>
              <span className="text-sm bg-[#E1F5FC] py-2 px-3 rounded-lg mt-2 inline-block">
                {study.category}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Dotted Pagination (Moved Below Carousel) */}
      <div className="mt-4 flex justify-center space-x-2">
        {carouselImages.map((_, index) => (
          <button
            key={index}
            className={`h-3 transition-all ${
              currentSlide === index
                ? "w-6 bg-[#000000] rounded-full"
                : "w-3 bg-gray-300 rounded-full"
            }`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
      {/* Horizontal Line */}
      <hr className="border-t-8 border-[#F4F4F4] my-8 mx-auto w-full mt-24" />
    </div>
  );
};

export default CaseStudiesPage;
