import React, { useState, useEffect } from "react";
// import rightsidearrow from "../assets/warehouse/rightarrow.webp";
import awslogo from "../assets/awslogo.webp";
import arrowblc from "../assets/arrowblk.webp";
import discount from "../assets/discount.webp";
import savings from "../assets/saving.webp";
import cost from "../assets/cost.webp";
import commitment from "../assets/commitment.webp";
import { useNavigate } from "react-router-dom";
// import Icon1 from "../assets/Icon1.webp";
import Group1 from "../assets/Group1.webp";
import Cloud from "../assets/cloud.webp";
import Group2 from "../assets/group2.webp";
import Hand from "../assets/get.gif";
import Hero from "../assets/saving.mp4";
import Heromobile from "../assets/savings warehouse mobile.mp4";
import { Helmet } from "react-helmet-async";

const Warehouse = () => {
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const cardData = [
    {
      title: "Discounts",
      description: "Immediate savings on AWS services without long-term plans.",
      icon: discount,
      alt: "Discount Icon",
    },
    {
      title: "No Commitment",
      description: "Use services flexibly with no binding contracts.",
      icon: commitment,
      alt: "No Commitment Icon",
    },
    {
      title: "Cost Transparency",
      description: "Clear visibility into savings for better budgeting.",
      icon: cost,
      alt: "Cost Transparency Icon",
    },
    {
      title: "Personalized Savings",
      description: "Customized discounts based on your usage patterns.",
      icon: savings,
      alt: "Personalized Savings Icon",
    },
  ];

  const awsServices = [
    'EC2',
    'Sagemaker',
    'Redshift',
    'EC2 Data Transfer',
    'ECS',
    'Lambda',
    'EBS',
    'ElasticCache',
    'OpenSearch',
    'RDS',
    'S3',
    'MediaLive'
  ];


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <>
      {/* Hero Section */}
      <div className="relative w-full min-h-[70vh] md:min-h-screen">
        <Helmet prioritizeSeoTags>
          <title>
            Savings warehouse | Cloudevolve | Slash your AWS bill with Savings
            Warehouse. Instant discounts to all AWS services with zero.
          </title>
          <link rel="Important" href="https://cloudevolve.com/platform/warehouse" />
          <meta
            name="description"
            content="Slash your AWS bill with Savings Warehouse. Instant discounts to all AWS services with zero."
          />
          <meta name="keywords" content="Cloudevolve, cloud cost savings, cloud solutions, innovation, AWS cost management" />
          <meta name="author" content="Cloudevolve" />

          <link rel="preload" as="video" href="/video.mp4" type="video/mp4" />
        </Helmet>

        {/* Video Background */}
        <video
          src={Hero}
          loading="lazy" effect="blur"
          autoPlay
          loop
          muted
          playsInline
          preload="metadata"
          className="absolute inset-0 w-full h-full object-cover sm:block hidden"
        />

        {/* Mobile Video (Hidden on Tablet & Desktop) */}
        <video
          src={Heromobile} // Replace with the mobile video source
          autoPlay
          loading="lazy" effect="blur"
          loop
          muted
          playsInline
          preload="metadata"
          className="absolute inset-0 w-full h-full object-cover sm:hidden"
        />



        {/* Hero Content */}
        <div className="relative z-10 mt-0 sm:mt-4  md:mt-20 flex flex-col items-center justify-start w-full h-full pt-16 md:pt-12">
          {/* Platform Tag */}
          <div className="py-2 px-3 rounded-lg font-bold shadow-lg bg-white">
            <span className="font-jakarta text-xs md:text-sm">Platform</span>
          </div>

          {/* Title & Subtitle */}
          <div className="mt-4 md:mt-6 text-center px-4">
            <h2 className="rubik font-rubik text-2xl md:text-4xl lg:text-4xl font-bold text-black text-center leading-8 lg:leading-[32px] tracking-normal">
              Savings Warehouse
            </h2>

            <p className="mt-2 md:mt-4 font-jakarta text-lg sm:text-sm md:text-lg max-w-2xl mx-auto text-center text-[#222222]">
              Get benefits of Savings plan without purchasing one.
            </p>
          </div>

          {/* CTA Button */}
          <div className="mt-4 md:mt-10">
            <button
              onClick={() => navigate("/bookyour")}
              className="bg-[#02AAE2] text-white font-jakarta px-4 py-2 rounded-lg font-semibold flex items-center gap-3 border border-black hover:bg-[#0298c8] transition-colors"
            >
              <span className="text-xs sm:text-sm md:text-base">Get Your Discounts</span>
              {/* <img src={rightsidearrow} className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" alt="Arrow Icon" /> */}
            </button>
          </div>
        </div>
      </div >

      {/* Discount Section */}
      < div className="text-center mt-10 pb-24" >
        <h3 className="rubik text-[22px] md:text-[30px] font-bold leading-[30px] md:leading-[48px] tracking-normal text-center text-black">
          Slash that bill!
        </h3>


        <h3 className="mt-2 font-jakarta" style={{ color: "#222222" }}>
          Get AWS service discounts without the commitment.
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 sm:px-10 md:px-14 lg:px-14 xl:px-14 2xl:px-[14rem] px-20 mt-10">
          {awsServices.map((service, index) => (
            <div
              key={index}
              className="rounded-2xl p-4 flex flex-col space-y-4 max-w-xs border border-gray-300"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="flex justify-between items-center">
                <div className="text-left">
                  <h4 className="text-lg text-black font-medium font-jakarta">{service}</h4>
                </div>
                <div className="flex items-center">
                  <img src={awslogo} alt="AWS Logo" loading="lazy" effect="blur" className="h-6" />
                </div>
              </div>
              <div className="flex items-center mt-6 relative">
                <img
                  src={arrowblc}
                  alt="Arrow Icon"
                  loading="lazy"
                  effect="blur"
                  className="h-9 mr-4 cursor-pointer"
                  onClick={() => navigate("/bookyour")}
                />

                {hoveredIndex === index && (
                  <div className="battery-animation absolute inset-0 flex items-center justify-center">
                    <button
                      className="relative w-full max-w-xs px-4 py-2 text-white rounded overflow-hidden"
                      onClick={() => navigate("/bookyour")} // Apply click event to the whole button
                    >
                      <span className="loading-bar absolute inset-0 h-full"></span>
                      <span className="relative z-10">Get Started</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

      </div >

      <hr className="border-[#F4F4F4] border-t-2" />

      {/* Benefits Section */}
      <div className="text-center mt-10 pb-8 px-4 md:px-8">
        <h3 className="rubik text-2xl md:text-4xl font-bold mt-16 text-black">
          Benefits of Savings Warehouse
        </h3>
        <p className="mt-2 font-jakarta text-black mb-8">
          Cloudevolve offers instant AWS discounts, to optimize cloud costs effectively.
        </p>

        <div className="relative">
          {/* Background Lines (visible on larger screens) */}
          <div className="hidden md:block">
            <div className="absolute lg:top-48 lg:ml-48 md:top-56 md:ml-12 lg:left-0 md:left-0 lg:w-[700px] md:w-[700px] xl:ml-80 lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4">
              {" "}
            </div>
            {/* Vertical Line */}
            <div className="absolute lg:left-1/2 md:left-1/2 lg:top-10 md:top-5 lg:h-[410px] md:h-[420px] w-0.5 bg-gray-25">
              {" "}
            </div>
          </div>

          {/* Benefits Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-20 mt-8 max-w-4xl mx-auto">
            {cardData.map((card, index) => (
              <div key={index} className="flex flex-col items-center text-center">
                <div className="h-16 w-16 flex items-center justify-center">
                  <img src={card.icon} alt={card.alt} loading="lazy" effect="blur" className="w-14 h-14" />
                </div>
                <h4 className="text-lg font-semibold mt-4 font-jakarta">
                  {card.title}
                </h4>
                <p className="text-sm text-black mt-2 font-jakarta max-w-[250px]">
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="relative w-full flex justify-center sm-max:w-full xl:h-[400px] mt-20 mx-auto m-5 lg:h-42 md:h-42  sm:p-0">
        {/* Group1 Image for non-mobile screens */}
        <img
          src={Group1}
          alt="Group"
          loading="lazy" effect="blur"
          className="hidden sm:block border-t-2 border-gray-900 lg:w-full md:w-full lg:h-auto md:h-auto sm:rounded-none h-[400px] object-cover"
        />

        {/* Group1 Alternative Image for mobile screens */}
        <img
          src={Group2}
          alt="Mobile Group"
          loading="lazy" effect="blur"
          className="block sm:w-full sm:hidden lg:w-full md:w-full lg:h-auto md:h-auto rounded-lg h-[400px] object-cover"
        />


        {/* Hand GIF 1 */}
        <div className="absolute right-0 w-24 h-24 md:w-30 md:h-30 mb-32 mt-5 lg:mb-44 mr-28 mobile-m:mr-36 mobile-l:mr-40 lg:hidden md:hidden">
          <img
            src={Hand}
            loading="lazy" effect="blur"
            alt="Hand GIF"
            className="w-full h-full"
          />
        </div>

        {/* Hand GIF 2 */}
        <div className="absolute bottom-0 right-0 md:mr-5 md:mb-[45px] lg:mb-[30px] lg:w-64 lg:h-72 xl:mb-[55px] xl:h-80 xl:w-[330px] xl:mr-5 w-24 h-24 md:w-44 md:h-48 mb-32 mr-14 hidden md:flex">
          <img
            src={Hand}
            loading="lazy" effect="blur"
            alt="Hand GIF"
            className="w-full h-full"
          />
        </div>

        {/* Vector624 Image */}
        <img
          src={Cloud}
          alt="Vector"
          loading="lazy" effect="blur"
          className="absolute w-full h-auto bottom-0 md:-bottom-0.5 lg:-bottom-1.5 left-0 xl:h-44 h-24 md:h-28 lg:h-32 px-3 sm:p-0 hidden sm:block"
        />

        {/* Border below vector image */}
        <div className="absolute bottom-0 left-0 w-full sm:h-4 h-2  rounded-lg"></div>

        <div className="absolute inset-0 flex flex-col items-center sm:items-start sm:justify-center px-4 lg:py-10 md:px-12 mb-20">
          <h3 className="font-bold rubik text-xl mt-32 sm:mt-0 ml-2 sm:ml-0 lg:ml-32 md:ml-0 xl:text-4xl lg:text-3xl md:text-2xl text-white mb-2 sm:p-0 m-0 text-center sm:text-left">
            Are you not ready to commit?
          </h3>
          <p className="font-normal font-jakarta ml-0 sm:ml-10 mr-0 sm:mr-12 lg:ml-32 md:ml-0 lg:mt-0 md:mt-0 text-base md:text-lg text-white mb-4 text-center sm:text-left w-10/12 sm:w-auto mx-auto">
            Step into the cloud with us - no pressure, just perks!
          </p>

          <div className="ml-0 sm:ml-24 md:ml-0 lg:ml-32 xl:ml-32">
            <button
              className="flex items-center bg-white px-4 py-2 md:px-2 md:py-2 rounded-lg border border-horizonOrange-950 hover:bg-gray-200"
              onClick={() => navigate("/contactus")}
            >
              <span className="text-black font-jakarta text-start text-xs font-bold md:text-base lg:text-base w-20 md:w-auto lg:w-auto text-center sm:text-left">
                Get in touch
              </span>
              {/* <img
                src={Icon1}
                alt="Icon"
                className="w-3 h-3 md:w-4 md:h-4 lg:w-5 lg:h-5 ml-2"
              /> */}
            </button>
          </div>
        </div>
      </div>

    </>
  );
};

export default Warehouse;