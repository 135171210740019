import React, { useEffect } from 'react';
// import Logo360 from '../assets/logo360.webp';
import Vector from '../assets/vector360.webp';
// import Frameblue from '../assets/Cloud Patrol.webp';
// import Frameblack from '../assets/Stop Idle Resources.webp';
import Checkbadge from '../assets/check-badge-01_1.gif';
// import Frameyellow from '../assets/Spend Insight.webp';
// import Frameorange from '../assets/Tag Orbit.webp';
// import Framepink from '../assets/Cost Fixer.webp';
import Frame1 from '../assets/Frame13.webp';
import Frame2 from '../assets/Frame2.webp';
import Frame3 from '../assets/Frame3.webp';
import Frame4 from '../assets/4.webp';
import { useNavigate } from "react-router-dom";
// import Icon1 from '../assets/Icon1.webp';
import Group1 from '../assets/Group1.webp';
// import Hand from "../assets/hand.webp";
// import Arrow from '../assets/rightsidearrow.webp';
import Cloud from '../assets/cloud.webp';
import checkCircleIcon from "../assets/check-badge-01_1.gif";
import Group2 from "../assets/group2.webp";
import Hero from "../assets/cloud 360 main.mp4";
import Hand from "../assets/get.gif";
import { Helmet } from "react-helmet-async";
import Cloudpatrol from "../assets/Patroll.gif";
import Spendinsight from "../assets/Insight.gif";
import Tagorbit from "../assets/Orbit.gif";
import Costfixer from "../assets/Fixer.gif";
import Stopidle from "../assets/resources.gif";
import Heromobile from "../assets/Cloud 360 mobile.mp4";



const Cloud360 = () => {
  const navigate = useNavigate();



  const cardData = [
    {
      title: "Cost Efficiency",
      description: "Optimize resource usage to ensure \nevery dollar counts.",
      icon: Frame1,
      alt: "Discount Icon",
    },
    {
      title: "Enhanced Security Posture",
      description: "Protect your cloud with proactive threat detection \nand continuous compliance.",
      icon: Frame2,
      alt: "No Commitment Icon",
    },
    {
      title: "Complete Visibility",
      description: "Gain a comprehensive view of your infrastructure for effective tracking and management.",
      icon: Frame3,
      alt: "Cost Transparency Icon",
    },
    {
      title: "Performance Optimization",
      description: "Receive actionable insights to right-size resources and reduce unnecessary costs.",
      icon: Frame4,
      alt: "Personalized Savings Icon",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white  min-h-screen flex flex-col items-center text-center">
      <Helmet prioritizeSeoTags>
        <title>Cloud 360 | Cloudevolve | The all-in-one intelligence platform offering cost insights, tagging, and security for a smarter, optimized AWS experience. </title>
        <link
          rel="Important"
          href="https://cloudevolve.com/platform/Cloud360"
        />
        <meta
          property="og:description"
          content="The all-in-one intelligence platform offering cost insights, tagging, and security for a smarter, optimized AWS experience."
        />
        <meta
          name="keywords"
          content="Cloudevolve, cloud cost savings, cloud solutions, innovation, AWS cost management"
        />
        <meta name="author" content="Cloudevolve" />

        <link rel="preload" as="video" href="/video.mp4" type="video/mp4" />
      </Helmet>

      <div className="flex justify-center mt-0 md:mt-20 items-center ">
        <div
          className="flex justify-center items-center text-black mt-12 py-2 px-3 rounded-lg font-bold bg-white shadow-lg w-fit"
        >
          <span className="font-jakarta text-sm">Platform</span>
        </div>
      </div>
      <main className="flex flex-col items-center justify-center mt-6">
        <h1 className="rubik text-4xl leading-[48px] text-center sm:text-5xl font-bold">Cloud 360°</h1>

        <p className="mt-2 font-jakarta text-lg">
          Get an overall insight of your workloads
        </p>
        <button
          className="mt-6 text-white font-jakarta px-3 py-2 rounded-lg  border border-black hover:bg-blue-700 "
          style={{ backgroundColor: '#02AAE2', border: '1px solid black' }}
          onClick={() => navigate("/bookyour")}
        >
          <div className="flex font-jakarta font-semibold">
            <div>Unlock Insights
            </div>
            {/* <div className="ml-2 mt-[2px]">
              <img src={Arrow} alt="icon" />
            </div> */}
          </div>
        </button>


        <div className="relative mt-0 w-full">
          {/* Desktop Video with Vector Image */}
          <div className="hidden sm:block relative w-full">
            <video
              src={Hero}
              loading="lazy"effect="blur"
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline="true"
              preload="metadata"
              className="w-full object-cover h-auto sm-max:h-[200px] sm-max:object-fill"
            />
            {/* Vector Image (Only for Desktop) */}
            <img
              src={Vector}
              loading="lazy"effect="blur"
              alt="Bottom"
              className="absolute -bottom-1 left-0 w-full h-auto object-cover"
            />
          </div>

          {/* Mobile Video (No Vector Image) */}
          <video
            src={Heromobile}
            autoPlay
            loading="lazy"effect="blur"
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            className="block sm:hidden w-full object-cover h-auto"
          />
        </div>


      </main>

      <div className="mt-0 ml-0 mobile-m:mr-36 mobile-l:mr-48 h-48 w-48 mr-20 lg:hidden md:hidden">
        <img
          src={Spendinsight}
          loading="lazy"effect="blur"
          alt="Frame Blue"
          className="h-full w-full"
        />
      </div>

      <div className=' flex m-5'>
        <div className=" items-center justify-center  ">
          <div className="text-left text-black font-jakarta md:text-xs lg:text-lg ">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black mt-0 md:mt-0 lg:mt-0 mb-[20px]">
              Spend Insight
            </h2>
            <p className="text-black">
              <span className="hidden lg:inline">
                Provides a complete view of cloud infrastructure,<br />
                including resource utilization, performance metrics, and<br />
                potential issues across the AWS cloud environment.
              </span>
              <span className="inline lg:hidden">
                Provides a complete view of cloud infrastructure, including resource utilization, performance metrics, and potential issues across the AWS cloud environment.
              </span>
            </p>

          </div>


          <div className="flex items-center mt-12 lg:mt-7 md:mt-7">
            <img src={Checkbadge} loading="lazy"effect="blur" alt="Check badge" className="mr-4 h-8 w-8" />
            <h2 className="text-base rubik  md:text-base  lg:text-xl font-bold text-left text-black">
              Real-time monitoring & alerts
            </h2>
          </div>
          <div className="flex items-center mt-8 md:mt-3">
            <img src={Checkbadge} loading="lazy"effect="blur" alt="Check badge" className="mr-4 h-8 w-8" />
            <h2 className="text-base rubik md:text-base  lg:text-xl font-bold text-left text-black">
              Smarter resource allocation
            </h2>
          </div>
          <div className="flex items-center mt-8 md:mt-3">
            <img src={Checkbadge} loading="lazy"effect="blur" alt="Check badge" className="mr-4 h-8 w-8" />
            <h2 className="text-base rubik md:text-base  lg:text-xl font-bold text-left text-black">
              Performance optimization
            </h2>
          </div>
        </div>
        <div className="ml-24 lg:h-80 lg:w-80 md:h-72 md:w-[500px] lg:flex md:flex hidden">
          <img
            src={Spendinsight}
            loading="lazy"effect="blur"
            alt="Spendinsight Animation"
            className="h-[85%] w-full"
          />
        </div>


      </div>



      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[30px] md:mt-[30px] md:mb-[0px] lg:mt-[50px] lg:mb-[50px] "></div>
      <div className='flex flex-col md:flex-row lg:flex-row m-5'>
        <div className="mb-1 mt-7 sm-max:h-48 sm-max:w-48 ml-18 h-10 mr-12 lg:h-80 lg:w-80 md:h-72 md:w-[550px] lg:flex md:flex">
          <img
            src={Cloudpatrol}
            loading="lazy"effect="blur"
            alt="Cloudpatrol Animation"
            className="w-full h-full"
          />
        </div>


        <div className=" items-center justify-center  mt-10 md:mt-10 lg:mt-10 ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black mt-0 mb-[10px]">
              Cloud Patrol
            </h2>
            <p>
              <span className="hidden lg:inline">
                Implements a unified security posture across all cloud<br />
                services, including identity management, data<br />
                protection, threat detection, and compliance with<br />
                industry standards.
              </span>
              <span className="inline lg:hidden">
                Implements a unified security posture across all cloud services, including identity management, data protection, threat detection, and compliance with industry standards.
              </span>
            </p>
          </div>

          <div className="flex items-center mt-12 md:mt-7">
            <img src={Checkbadge} loading="lazy"effect="blur" alt="Check badge" className="mr-4 h-8 w-8" />
            <h2 className="text-base rubik  md:text-base  lg:text-xl font-bold text-left text-black">
              Centralized threat detection
            </h2>
          </div>
          <div className="flex items-center mt-8 md:mt-3">
            <img src={Checkbadge} loading="lazy"effect="blur" alt="Check badge" className="mr-4 h-8 w-8" />
            <h2 className=" rubik text-base md:text-base lg:text-xl font-bold text-left text-black">
              Compliance with industry regulations
            </h2>
          </div>
          <div className="flex items-center mt-8 md:mt-3">
            <img src={Checkbadge} loading="lazy"effect="blur" alt="Check badge" className="mr-4 h-8 w-8" />
            <h2 className="rubik text-base md:text-base lg:text-xl font-bold text-left text-black">
              24/7 monitoring and incident response
            </h2>
          </div>
        </div>
      </div>



      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[30px] md:mt-[50px] md:mb-[0px] lg:mt-[80px] lg:mb-[10px]"></div>
      <div className="mt-10 mb-6 ml-0 h-48 w-48 mr-20 mobile-m:mr-36 mobile-l:mr-48 lg:hidden md:hidden">
        <img
          src={Tagorbit}
          loading="lazy"effect="blur"
          alt="Tagorbit Animation"
          className="h-full w-full"
        />
      </div>


      <div className=' flex m-5'>
        <div className=" items-center justify-center ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black  md:mt-24 mb-[20px]">
              Tag Orbit
            </h2>
            <p>
              <span className="hidden lg:inline">
                Our real-time tagging solution empowers you to
                categorize,<br /> manage, and analyse your cloud resources
                effortlessly.
              </span>
              <span className="inline lg:hidden">
                Our real-time tagging solution empowers you to categorize, manage, and analyse your cloud resources effortlessly.
              </span>
            </p>
          </div>

        </div>
        <div className="mt-20 md:mt-0 lg:mt-10 ml-24 h-10 lg:h-72 lg:w-72 md:h-72 md:w-[400px] lg:flex md:flex hidden">
          <img
            src={Tagorbit}
            loading="lazy"effect="blur"
            alt="Tagorbit Animation"
            className="w-full h-[80%]" 
          />
        </div>



      </div>


      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 md:gap-24 lg:gap-16 max-w-5xl mx-auto  ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4  md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Custom Reports</span>
            <span className="inline md:hidden">Custom Reports</span>
          </h3>
          <p className="text-black-400 font-jakarta ">
            <span className="hidden md:inline">
              Generate tailored reports to visualize<br></br> resource usage andcx identify <br></br>optimization opportunities.
            </span>
            <span className="inline md:hidden ">
              Generate tailored reports to visualize resource usage and identify optimization opportunities.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Enhanced Cost Management
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Easily track costs associated with<br></br> specific projects or departments.
            </span>
            <span className="inline md:hidden">
              Easily track costs associated with specific projects or departments.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Improved Governance</span>
            <span className="inline md:hidden">Improved Governance</span>
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Maintain compliance and<br></br> streamline resource audits with<br></br> clear tagging strategies.
            </span>
            <span className="inline md:hidden">
              Maintain compliance and streamline resource audits with clear tagging strategies.
            </span>
          </p>
        </div>
      </div>








      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[40px] md:mt-[50px] md:mb-[0px] lg:mt-[80px] lg:mb-[30px]"></div>
      <div className='flex flex-col md:flex-row lg:flex-row m-5'>
        <div className="mb-1 mt-6 md:mt-0 sm-max:h-48 sm-max:w-48 lg:mt-16 ml-18 h-10 mr-12 lg:h-80 lg:w-80 md:h-72 md:w-64 lg:flex md:flex">
          <img
            src={Costfixer}
            loading="lazy"effect="blur"
            alt="Costfixer Animation"
            className="h-[80%] w-full"
          />
        </div>


        <div className=" items-center justify-center  mt-10 md:mt-0 lg:mt-0 ">
          <div className="text-black font-jakarta ml-0 text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black mt-0 md:mt-10 lg:mt-20 mb-[10px]">
              <span className="hidden md:inline">Cost Fixer</span>
              <span className="inline md:hidden">Cost Fixer</span>
            </h2>
            <p>
              <span className="hidden md:inline">
                Our Cost Fixer provide intelligent
                analytics to identify<br></br> underutilized resources, enabling
                you to adjust capacities<br /> and reduce unnecessary costs.
                Whether it’s scaling down <br />an oversized instance or
                boosting underperforming ones,<br /> we've got you covered.
              </span>
              <span className="inline md:hidden">
                Our Right-Sizing Recommendations provide intelligent analytics to identify underutilized resources, enabling you to adjust capacities and reduce unnecessary costs. Whether it’s scaling down an oversized instance or boosting underperforming ones, we've got you covered.
              </span>
            </p>
          </div>

        </div>
      </div>


      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Cost Efficiency</span>
            <span className="inline md:hidden">Cost Efficiency</span>
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Automatically identify over-<br></br>provisioned resources and reduce<br></br> unnecessary expenses.
            </span>
            <span className="inline md:hidden">
              Automatically identify over-provisioned resources and reduce unnecessary expenses.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Performance Boost</span>
            <span className="inline md:hidden">Performance Boost</span>
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Ensure that workloads run<br></br> smoothly with the right-sized <br></br>resources.
            </span>
            <span className="inline md:hidden">
              Ensure that workloads run smoothly with the right-sized resources.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Custom Fit</span>
            <span className="inline md:hidden">Custom Fit</span>
          </h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Recommendations based on your<br />
              business needs and actual usage<br />
              trends.
            </span>
            <span className="inline md:hidden">
              Recommendations based on your business needs and actual usage trends.
            </span>
          </p>
        </div>
      </div>





      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[50px] md:mb-[0px] lg:mt-[70px] lg:mb-[40px]"></div>
      <div className="mt-16 ml-0 h-48 w-48 mr-20 mobile-m:mr-36 mobile-l:mr-48 lg:hidden md:hidden">
        <img
          src={Stopidle}
          loading="lazy"effect="blur"
          alt="Stopidle Animation"
          className="h-full w-full"
        />
      </div>


      <div className=' flex m-5'>
        <div className=" items-center justify-center " >
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black md:mt-16 lg:mt-20 mb-[10px]">
              <span className="hidden md:inline">Stop Idle Resources</span>
              <span className="inline md:hidden">Stop Idle Resources</span>
            </h2>
            <p>
              <span className="hidden md:inline">
                Idle resources are silent budget killers. You can identify<br />
                and eliminate unused or underutilized resources before<br />
                they add up to unexpected costs. Don’t let dormant<br />
                resources drain your cloud budget.
              </span>
              <span className="inline md:hidden">
                Idle resources are silent budget killers. You can identify and eliminate unused or underutilized resources before they add up to unexpected costs. Don’t let dormant resources drain your cloud budget.
              </span>
            </p>
          </div>

        </div>
        <div className="mt-48 md:mt-0 lg:mt-14 ml-24 md:ml-20 h-10 lg:h-80 lg:w-80 md:h-72 md:w-64 lg:flex md:flex hidden">
          <img
            src={Stopidle}
            loading="lazy"effect="blur"
            alt="Stopidle Animation"
            className="h-[70%] w-full"
          />
        </div>



      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Automated Alerts</span>
            <span className="inline md:hidden">Automated Alerts</span>
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Receive real-time notifications for<br></br> unused resources so you can act<br></br> instantly.
            </span>
            <span className="inline md:hidden">
              Receive real-time notifications for unused resources so you can act instantly.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Immediate Savings</span>
            <span className="inline md:hidden">Immediate Savings</span>
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Cut out waste by shutting down<br></br> idle resources.
            </span>
            <span className="inline md:hidden">
              Cut out waste by shutting down idle resources.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Ongoing Optimization</span>
            <span className="inline md:hidden">Ongoing Optimization</span>
          </h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Regular scans to ensure your<br></br> cloud environment remains lean<br></br> and cost-effective.
            </span>
            <span className="inline md:hidden">
              Regular scans to ensure your cloud environment remains lean and cost-effective.
            </span>
          </p>
        </div>
      </div>


      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[50px] md:mt-[100px] lg:mt-[100px]"></div>
      <div className="text-center mt-[50px] md:mt-[100px] lg:mt-[100px] mb-[20px] pb-5" >
        <h3 className="rubik text-4xl font-bold leading-[48px] text-center" style={{ color: "#000000" }}>
          Benefits of Cloud 360°
        </h3>
        < h3 className="font-normal mt-2 font-jakarta" style={{ color: "#222222" }}>
          At Cloudevolve, your security is our top priority. Our advanced tools offer:
        </h3>

        < div className="relative flex flex-col justify-center items-center" >
          {/* Horizontal Line */}
          < div className="absolute lg:top-60 lg:ml-36 md:top-60 md:ml-0 lg:left-0 md:left-0 lg:w-[740px] md:w-[750px] xl:ml-36 lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4" > </div>
          {/* Vertical Line */}
          <div className="absolute lg:left-1/2 md:left-1/2 lg:top-10 md:top-5 lg:h-[410px] md:h-[420px] w-0.5 bg-gray-25" > </div>
          < div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-40 gap-y-16 mt-12 px-4 lg:px-40" >
            {
              cardData.map((card, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center text-center w-full"
                >
                  <div className="h-16 w-16 flex items-center justify-center rounded" >
                    <img src={card.icon} loading="lazy"effect="blur" alt={card.alt} className="w-14 h-14" />
                  </div>
                  < h4 className="text-lg font-semibold mt-4 rubik" > {card.title} </h4>
                  < p
                    className="text-sm text-black font-jakarta mt-2"
                    style={{ color: "#222222", maxWidth: '280px' }}
                  >
                    {card.description}
                  </p>
                </div>
              ))}
          </div>
        </div>


      </div>





      <div className="relative w-full flex justify-center sm-max:w-full xl:h-[400px] mt-20 mx-auto m-5 lg:h-42 md:h-42  sm:p-0">
        {/* Group1 Image for non-mobile screens */}
        <img
          src={Group1}
          alt="Group"
          loading="lazy"effect="blur"
          className="hidden sm:block border-t-2 border-gray-900 lg:w-full md:w-full lg:h-auto md:h-auto sm:rounded-none h-[400px] object-cover"
        />

        {/* Group1 Alternative Image for mobile screens */}
        <img
          src={Group2}
          alt="Mobile Group"
          loading="lazy"effect="blur"
          className="block sm:w-full sm:hidden lg:w-full md:w-full lg:h-auto md:h-auto rounded-lg h-[400px] object-cover"
        />


        {/* Hand GIF 1 */}
        <div className="absolute right-0 w-24 h-24 md:w-30 md:h-30 mb-32 mt-5 lg:mb-44 mr-28 mobile-m:mr-36 mobile-l:mr-40 lg:hidden md:hidden">
          <img
            src={Hand}
            alt="Hand GIF"
            loading="lazy"effect="blur"
            className="w-full h-full"
          />
        </div>

        {/* Hand GIF 2 */}
        <div className="absolute bottom-0 right-0 md:mr-5 md:mb-[45px] lg:mb-[30px] lg:w-64 lg:h-72 xl:mb-[55px] xl:h-80 xl:w-[330px] xl:mr-5 w-24 h-24 md:w-44 md:h-48 mb-32 mr-14 hidden md:flex">
          <img
            src={Hand}
            alt="Hand GIF"
            loading="lazy"effect="blur"
            className="w-full h-full"
          />
        </div>

        {/* Vector624 Image */}
        <img
          src={Cloud}
          alt="Vector"
          loading="lazy"effect="blur"
          className="absolute w-full h-auto bottom-0 md:-bottom-0.5 lg:-bottom-1.5 left-0 xl:h-44 h-24 md:h-28 lg:h-32 px-3 sm:p-0 hidden sm:block"
        />

        {/* Border below vector image */}
        <div className="absolute bottom-0 left-0 w-full sm:h-4 h-2  rounded-lg"></div>

        <div className="absolute inset-0 flex flex-col items-center sm:items-start sm:justify-center px-4 lg:py-10 md:px-12 mb-20">
          <h3 className="font-bold rubik text-xl mt-32 sm:mt-0 ml-2 sm:ml-0 lg:ml-32 md:ml-0 xl:text-4xl lg:text-3xl md:text-2xl text-white mb-2 sm:p-0 m-0 text-center sm:text-left">
            Are you not ready to commit?
          </h3>
          <p className="font-normal font-jakarta ml-0 sm:ml-10 mr-0 sm:mr-12 lg:ml-32 md:ml-0 lg:mt-0 md:mt-0 text-base md:text-lg text-white mb-4 text-center sm:text-left w-10/12 sm:w-auto mx-auto">
            Step into the cloud with us - no pressure, just perks!
          </p>

          <div className="ml-0 sm:ml-24 md:ml-0 lg:ml-32 xl:ml-32">
            <button
              className="flex items-center bg-white px-4 py-2 md:px-2 md:py-2 rounded-lg border border-horizonOrange-950 hover:bg-gray-200"
              onClick={() => navigate("/contactus")}
            >
              <span className="text-black font-jakarta text-start text-xs font-bold md:text-base lg:text-base w-20 md:w-auto lg:w-auto text-center sm:text-left">
                Get in touch
              </span>
              {/* <img
                src={Icon1}
                alt="Icon"
                className="w-3 h-3 md:w-4 md:h-4 lg:w-5 lg:h-5 ml-2"
              /> */}
            </button>
          </div>
        </div>
      </div>










    </div>
  );
};

export default Cloud360;
