import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import bookcvr from "../assets/bookcvr.webp";
import CalendlyEmbed from "./Calendly";
import { Helmet } from "react-helmet-async";
import plus from "../assets/plus.webp";
import xmark from "../assets/xmark.webp";

// List of assets to cache
const assets = [bookcvr, plus, xmark];

// Function to preload images
const preloadAssets = () => {
  assets.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

const Bookyourdate = () => {
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    preloadAssets();
  }, []);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const faqs = [
    { question: "Are there any commitments required?", answer: "No, we believe in flexibility! You can enjoy savings without any long-term commitments." },
    { question: "Is there any fee to join?", answer: "No, there is no fee to join." },
    { question: "What makes Cloudevolve different from other platforms?", answer: "Cloudevolve offers competitive pricing and seamless integration." },
    { question: "How often does pricing adjust to usage changes?", answer: "Pricing is adjusted based on your usage every month." },
    { question: "Can I access detailed reports on my spending?", answer: "Yes, you can access detailed spending reports through the dashboard." },
    { question: "What happens if my cloud usage changes?", answer: "Your pricing adjusts automatically to reflect your current usage" },
    { question: "Are there any hidden fees?", answer: "No, we maintain complete transparency with our pricing." },
  ];


  return (
    <div className="min-h-screen items-center justify-center">
      <Helmet>
        <title>Book a demo | Cloudevolve | Book your demo today and discover how we can save you up to 60% on AWS without commitments.</title>
        <meta
          name="description"
          content="Book your demo today and discover how we can save you up to 60% on AWS without commitments."
        />
        <meta
          name="keywords"
          content="Cloudevolve, cloud cost savings, cloud solutions, innovation, AWS cost management"
        />
        <meta name="author" content="Cloudevolve" />
        <link rel="preload" as="video" href="/video.mp4" type="video/mp4" />
      </Helmet>

      <div className="min-h-screen mt-0 md:mt-20 flex flex-col justify-center items-center space-y-10">
        <div className="w-full max-w-4xl bg-white rounded-lg">
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center text-black mt-16 py-0 px-0 rounded-lg font-bold bg-white shadow-lg w-28 h-8">
              <span className="font-jakarta text-sm">Get in touch</span>
            </div>
          </div>

          <div className="text-center mt-6">
            <h2 className="text-4xl font-bold" style={{ color: "#000000" }}>
              Book your date with Savings!
            </h2>
            <h2
              className="font-medium mt-5 w-[80%] mx-auto text-center"
              style={{ color: "#222222" }}
            >
              Ready to dive into a world of cloud savings? Scheduling a demo
              with us is your first step toward slashing those pesky AWS bills!
              Choose a date that works for you on our calendar, and let’s embark
              on this journey together.
            </h2>
          </div>
        </div>

        <div className="w-full max-w-[50rem] bg-white rounded-lg shadow-lg">
          <div className="w-full">
            <CalendlyEmbed className="w-full" />
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center mt-2 mb-5">
        <img src={bookcvr} alt="bookcvr" loading="lazy" effect="blur" className="w-full h-auto" />
      </div>

      {/* FAQ Section */}
      <section className="pt-16 px-2 bg-white">
        <h2 className="rubik text-4xl font-bold leading-[48px] text-center mb-10">
          Frequently Asked Questions
        </h2>
        <div className="max-w-4xl mx-auto space-y-4 group " style={{ border: "2px solid black", borderRadius: "25px", padding: "20px" }}>
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="rounded-lg p-4 shadow-sm  cursor-pointer"
              onClick={() => toggleFAQ(index)}
            >
              <div className="flex justify-between items-center font-bold text-black-800 rubik">
                {faq.question}
                <span className={`text-black-500 transform transition-transform duration-200 ${openIndex === index ? 'rotate-45' : ''}`}>
                  +
                </span>
              </div>
              {openIndex === index && (
                <p className="mt-3 text-black-600 font-jakarta leading-relaxed">
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Bookyourdate;
