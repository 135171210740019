import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Logo from '../assets/logo.webp';
import Twitter from '../assets/twitter.webp';
// import Facebook from '../assets/facebook.webp';
import Internet from '../assets/linkedin 1.webp';
// import Insta from '../assets/insta.webp';
import Youtube from '../assets/youtube.webp';
import Cloudevolve from '../assets/cloudevolve.webp';
import Bottom from "../assets/bottom.svg";

// List of assets to cache
const assets = [
    Logo, Twitter, Internet, Youtube, Cloudevolve, Bottom
];

// Function to preload images and videos
const preloadAssets = async () => {
    // Preload images
    assets.forEach((src) => {
        const img = new Image();
        img.src = src;
    });

    // Preload videos (if any)
    const videoUrls = ["/path/to/video1.mp4", "/path/to/video2.mp4"];
    videoUrls.forEach((url) => {
        fetch(url, { method: "GET", cache: "force-cache" })
            .then((response) => response.blob())
            .catch((error) => console.error("Video preload failed:", error));
    });
};



const Footer = () => {

    const navigate = useNavigate();
    const [, setDropdownState] = useState(null);



    useEffect(() => {
        preloadAssets();
    }, []);

    return (
        <div className="relative bg-white w-full flex flex-col items-center mt-auto">
            {/* Box-shadowed Horizontal Line */}
            <div className="w-full h-1.5 bg-gray-100 shadow-lg mt-[10px] md:mt-[70px] lg:mt-[100px] 2xl:mt-[120px]"></div>

            <div className="flex flex-col lg:flex-row md:flex-row lg:justify-evenly md:justify-around lg:mt-20 w-full  pt-10 mx-auto">
                {/* Left Section */}
                <div className="flex flex-col lg:items-start md:items-start text-left mb-8 lg:mb-0  items-center">
                    <div className="w-44 lg:w-52 md:w-52 h-auto">
                        <img src={Logo} loading="lazy" effect="blur" alt="Check badge" />
                    </div>
                    <p className="text-sm font-jakarta lg:text-base mt-4 text-gray-500 hidden lg:block md:block">
                        © 2025 Cloudevolve Inc<br />
                        All rights reserved.
                    </p>
                    <p
                        className="text-sm font-jakarta lg:text-base text-gray-500 hover:underline hover:text-[#02AAE2] cursor-pointer hidden md:block"
                        onClick={() => navigate("/privacy-policy")}
                    >
                        Privacy Policy | Terms of Service
                    </p>


                    <div className="flex mt-5 space-x-4 lg:flex md:flex hidden">
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <img src={Twitter} loading="lazy" effect="blur" alt="Twitter" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://www.linkedin.com/company/cloudevolve-com/" target="_blank" rel="noopener noreferrer">
                            <img src={Internet} loading="lazy" effect="blur" alt="Website" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                            <img src={Youtube} loading="lazy" effect="blur" alt="YouTube" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                    </div>
                </div>

                {/* Get in Touch Section */}
                <div className="hidden md:flex flex-col text-left mb-8 lg:mb-0  lg:items-start md:items-start items-center">
                    <h2 className="text-xl rubik lg:text-xl font-semibold text-black">Visit us</h2>
                    <p className="text-sm font-jakarta lg:text-base mt-2 text-gray-500">16192, Coastal Highway,</p>
                    <p className="text-sm font-jakarta lg:text-base text-gray-500">Lewes, Delaware, <br></br>19958-3608,</p>
                    <p className="text-sm font-jakarta lg:text-base text-gray-500">United States</p>
                    <h2 className="text-xl rubik lg:text-xl font-semibold text-black mt-2">Contact Us</h2>
                    <a href="mailto:info@cloudevolve.com" className="text-sm font-jakarta lg:text-base mt-2 text-gray-500 hover:underline hover:text-[#02AAE2]">
                        info@cloudevolve.com
                    </a>
                    <a href="tel:+16507033689" className="text-sm font-jakarta lg:text-base text-gray-500 hover:underline hover:text-[#02AAE2]">
                        +16507033689
                    </a>
                </div>


                {/* Explore More Section */}
                <div className="flex flex-col lg:items-start md:items-start items-center ">
                    <h2 className="text-xl rubik lg:text-xl font-semibold text-black">Explore more</h2>
                    {[
                        { name: "About Us", path: "/Aboutus" },
                        { name: "Cloud 360", path: "/platform/Cloud360" },
                        { name: "Savings warehouse", path: "/platform/warehouse" },
                        { name: "Savings Copilot", path: "/platform/savingsco-pilot", disabled: true },
                        { name: "Services", path: "/cloudmanagedservices" },
                        { name: "Pricing", path: "/Pricing" },
                        { name: "Testimonials", path: "/Testimonials" },
                    ].map((item, index) => (
                        <p key={index} className="text-sm font-jakarta lg:text-base text-gray-500 mt-2 ">
                            <button
                                className={`${item.disabled
                                    ? "text-gray-400 cursor-not-allowed"
                                    : "hover:underline hover:text-[#02AAE2]"
                                    }`}
                                onClick={() => {
                                    if (!item.disabled) {
                                        navigate(item.path);
                                        window.scrollTo(0, 0);
                                        setDropdownState(null);
                                    }
                                }}
                                disabled={item.disabled}
                            >
                                {item.name}
                            </button>
                        </p>
                    ))}
                </div>


                <div className="flex flex-col lg:items-start md:items-start items-center">
                    <h2 className="text-xl rubik lg:text-xl font-semibold text-black mt-3 sm:mt-0">
                        Resources
                    </h2>
                    {[
                        { name: "FAQ", path: "/faq" },
                        { name: "Blogs", path: "/blogs" },
                        { name: "Case Studies", path: "/platform/case-study", disabled: true },
                        { name: "Documentation", path: "/Documentation" },
                    ].map((item, index) => (
                        <p key={index} className="text-sm font-jakarta lg:text-base text-gray-500 mt-2">
                            <button
                                className={`hover:text-[#02AAE2] hover:underline transition-colors duration-200 ${item.disabled ? "text-gray-400 cursor-not-allowed hover:text-gray-400 hover:no-underline" : ""
                                    }`}
                                onClick={() => {
                                    if (item.disabled) return;
                                    if (item.name === "Documentation") {
                                        window.open("https://docs.cloudevolve.com/docs/intro", "_blank");
                                    } else {
                                        navigate(item.path);
                                        window.scrollTo(0, 0);
                                    }
                                    setDropdownState(null);
                                }}
                                disabled={item.disabled}
                            >
                                {item.name}
                            </button>
                        </p>
                    ))}
                </div>





                <div className="flex flex-col text-left mt-8 mb-0 lg:mb-0 lg:items-start md:items-start items-center sm:block md:hidden">
                    <h2 className="text-xl rubik lg:text-xl font-semibold text-black">Visit us</h2>
                    <p className="text-sm font-jakarta lg:text-base mt-2 text-gray-500">16192, Coastal Highway,</p>
                    <p className="text-sm font-jakarta lg:text-base text-gray-500">Lewes, Delaware, 19958-3608,</p>
                    <p className="text-sm font-jakarta lg:text-base text-gray-500">United States</p>
                    <h2 className="text-xl rubik lg:text-xl font-semibold text-black mt-2">Contact Us</h2>
                    <a href="mailto:info@cloudevolve.com" className="text-sm font-jakarta lg:text-base mt-2 text-gray-500 hover:underline hover:text-[#02AAE2]">
                        info@cloudevolve.com
                    </a>
                    <a href="tel:+16507033689" className="text-sm font-jakarta lg:text-base text-gray-500 hover:underline hover:text-[#02AAE2]">
                        +16507033689
                    </a>
                </div>



                <p className="text-sm jakarta flex flex-col items-center justify-center lg:text-base mt-8 text-gray-500 lg:hidden md:hidden block">
                    <span>© 2025 Cloudevolve.</span>
                    <span>All rights reserved.</span>
                </p>
                <p
                    className="text-sm font-jakarta lg:text-base text-gray-500 hover:text-[#02AAE2] flex items-center justify-center hover:underline cursor-pointer lg:hidden md:hidden block"
                    onClick={() => navigate("/privacy-policy")}
                >
                    Privacy Policy | Terms of service
                </p>

                <div className="flex items-center justify-center mt-5 space-x-4 md:hidden lg:hidden">
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <img src={Twitter} loading="lazy" effect="blur" alt="Twitter" className="w-3 h-3 lg:w-5 lg:h-5" />
                    </a>
                    <a href="https://www.linkedin.com/company/cloudevolve-com/" target="_blank" rel="noopener noreferrer">
                        <img src={Internet} loading="lazy" effect="blur" alt="Website" className="w-3 h-3 lg:w-5 lg:h-5" />
                    </a>
                    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                        <img src={Youtube} loading="lazy" effect="blur" alt="YouTube" className="w-3 h-3 lg:w-5 lg:h-5" />
                    </a>
                </div>
            </div>

            {/* Footer Background Section */}
            <div className="relative w-full mt-8">
                <div className="flex items-center justify-center">
                    <img
                        src={Cloudevolve}
                        alt="Vector"
                        loading="lazy" effect="blur"
                        className="mt-[26px] w-[260px] sm:w-[305px] sm:mt-6 md:mt-6 md:w-full mobile-l-max:mt-20 mobile-m-max:mt-20 lg:mt-4 lg:w-full custom-1440:mt-14 h-auto"
                    />
                </div>

                {/* Bottom Scrolling Image */}
                <div className="absolute top-20 left-0 w-full overflow-hidden whitespace-nowrap">
                    <div className="flex animate-scroll-left-to-right-footer">
                        <img src={Bottom} alt="Base Vector" loading="lazy" effect="blur" className="w-auto h-auto" />
                        <img src={Bottom} alt="Base Vector" loading="lazy" effect="blur" className="w-auto h-auto" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
