import React from "react";
// import icon from '../assets/Icon.webp';
import Vector625 from "../assets/Vector 625.webp";
import Increased from "../assets/1.webp";
import Limited from "../assets/2.webp";
import Cost from "../assets/3.webp";
import Scalability from "../assets/4.webp";
import Compliance from "../assets/5.webp";
import Security from "../assets/6.webp";

// import Ourpartners from "../assets/Our Partners.webp";
import Microsoft from "../assets/microsoft.webp";
import Aws from "../assets/aws.webp";
import Google from "../assets/google.webp";
import { useNavigate } from "react-router-dom";
import Cloudinfra from "../assets/Cloud Infrastructure managementt.mp4";
import Cloudmigration from "../assets/Cloud migration.mp4";
import Cloudsecurity from "../assets/Cloud security.mp4";
import Clouddeployment from "../assets/Cloud deployment.mp4";
import Disaster from "../assets/Disaster recoveryy.mp4";
import Cloudapplication from "../assets/CAD.mp4";
import Cloudmonitor from "../assets/Cloud monitoring.mp4";
import Dataanalytics from "../assets/Data Analytics and BI.mp4";
import Twentyfour from "../assets/7support1.mp4";
// import Logoservices from '../assets/Logoservices.webp';
// import Icon1 from '../assets/Icon1.webp';
// import Arrow from "../assets/rightsidearrow.webp";
import checkCircleIcon from "../assets/check-badge-01_1.gif";
import Hero from "../assets/service.mp4";
import { Helmet } from "react-helmet-async";






const Cloudmanagedservices = () => {
  const navigate = useNavigate();

  

  const cardData = [
    {
      title: "Increased Downtime",
      description:
        "Without expert management, your cloud \ninfrastructure is more prone to outages, \ncosting time and money.",
      icon: Increased,
      alt: "Increased ",
    },
    {
      title: "Limited Support",
      description:
        "Protect your cloud with proactive threat \ndetection and continuous compliance.",
      icon: Limited,
      alt: "Limited",
    },
    {
      title: "Cost Overruns",
      description:
        "Inefficient resource management can \nresult in over-provisioning and \ninflated cloud bills.",
      icon: Cost,
      alt: "Cost",
    },
    {
      title: "Scalability Issues",
      description:
        "Managing rapid growth becomes a \nchallenge without automated processes.",
      icon: Scalability,
      alt: "Scalability",
    },
    {
      title: "Compliance Risks",
      description:
        "Staying updated with regulatory \nrequirements can be tricky without \nprofessional help.",
      icon: Compliance,
      alt: "Compliance",
    },
    {
      title: "Security Gaps",
      description:
        "In-house teams may not have the \nexpertise to handle evolving cyber threats, leaving your data at risk.",
      icon: Security,
      alt: "Security",
    },
  ];

  return (
    <div className="bg-white w-full min-h-screen flex flex-col items-center text-center">
      <Helmet prioritizeSeoTags>
        <title>Services | Cloudevolve | From migration to monitoring, Cloudevolve cloud-managed services ensure your AWS runs smoothly, securely, and cost-effectively.</title>
        <link
          rel="Important"
          href="https://cloudevolve.com/cloudmanagedservices"
        />
        <meta
          name="description"
          content=" 

             From migration to monitoring, Cloudevolve’s cloud-managed services ensure your AWS runs smoothly, securely, and cost-effectively."
        />
        <meta
          name="keywords"
          content="Cloudevolve, cloud cost savings, cloud solutions, innovation, AWS cost management"
        />
        <meta name="author" content="Cloudevolve" />
        <link rel="preload" as="video" href="/video.mp4" type="video/mp4" />
      </Helmet>

      {/* Header Section */}
      <div className="flex justify-center mt-10 sm:mt-10 md:mt-32 items-center">
        <div className="flex justify-center items-center text-black py-2 px-3 rounded-lg shadow-lg bg-white  font-bold w-fit">
          <span className="font-jakarta text-sm">Services</span>
        </div>
      </div>

      

      {/* Main Content */}
      <main className="flex flex-col items-center justify-center mt-0 px-4 sm:px-8 text-center">
        <h1 className="text-2xl sm:text-3xl md:text-4xl rubik font-bold mt-6">
          Cloud Managed Services
        </h1>
        <p className="mt-4 text-base sm:text-lg font-jakarta leading-relaxed max-w-4xl px-4 sm:px-0">
          Managing the cloud can be complex, but it doesn’t have to be with
          Cloudevolve’s managed services. We simplify the journey, ensuring your
          cloud infrastructure runs seamlessly behind the scenes. From smarter
          operations to cost control, we help you harness the power of the cloud
          without the hassle—allowing you to focus on innovation and growth.
        </p>
        <button
          className="mt-6 text-white px-5 py-2 rounded-lg border border-gray-900 hover:bg-blue-700 flex items-center"
          style={{ backgroundColor: "#02AAE2" }}
          onClick={() => navigate("/bookyour")}
        >
          <span className="rubik font-bold">Get in touch</span>
          {/* <img className="ml-2 w-4 h-4" src={Arrow} alt="icon" /> */}
        </button>

        {/* Video Section */}
        <div className="relative w-full mt-6">
          <video
            src={Hero}
            autoPlay
            loading="lazy"effect="blur"
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            className="w-400px max-h-[400px] object-cover z-0 pointer-events-none"
          />
        </div>
      </main>

      {/* Footer Decoration */}
      <div className="w-full h-20 mt-0">
        <img
          className="w-full object-cover"
          src={Vector625}
          loading="lazy"effect="blur"
          alt="Vector Decoration"
        />
      </div>

      <div className="mt-0 ml-0 h-36 w-52 md:w-96 md:h-96 mr-20 mobile-m:mr-36 mobile-l:mr-48 lg:hidden md:hidden">
        <video
          src={Cloudinfra}
          loading="lazy"effect="blur"
          alt="Frame Blue"
          className="h-full w-full"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          preload="metadata"
        />
      </div>
      <div className=" flex m-5">
        <div className=" items-center justify-center ">
          <div className="text-black text-left font-jakarta md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-3xl font-bold rubik text-black mt-0 md:mt-32 lg:mt-32 mb-[20px]">
              <span className="hidden md:inline">
                Cloud Infrastructure <br /> management
              </span>
              <span className="inline md:hidden">
                Cloud Infrastructure management
              </span>
            </h2>
            <p>
              <span className="hidden md:inline">
                Navigate the complexities of cloud infrastructure
                <br />
                with our expert management services. We optimize
                <br /> performance, reduce costs, and ensure your systems
                <br /> are always running smoothly.
                <br />
              </span>
              <span className="inline md:hidden">
                Navigate the complexities of cloud infrastructure with our
                expert management services. We optimize performance, reduce
                costs, and ensure your systems are always running smoothly.
              </span>
            </p>
          </div>
        </div>
        <div className="mt-36 md:mt-20 ml-24 h-10 lg:h-80 lg:w-[420px] md:h-64 md:w-72 lg:flex md:flex hidden">
          <video
            src={Cloudinfra}
            loading="lazy"effect="blur"
            alt="cloudinfra"
            className="h-full w-full"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 lg:mt-8 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Reliability</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Ensure smooth operations with<br></br> automated maintenance.
            </span>
            <span className="inline md:hidden">
              Ensure smooth operations with automated maintenance.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Resource Optimization</span>
            <span className="inline md:hidden">Resource Optimization</span>
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Use only what you need,<br></br>when you need it.
            </span>
            <span className="inline md:hidden">
              Use only what you need,when you need it.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Performance Enhancement</span>
            <span className="inline md:hidden">Performance Enhancement</span>
          </h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Improve efficiency with continuous<br></br> tuning and load
              balancing.
            </span>
            <span className="inline md:hidden">
              Improve efficiency with continuous tuning and load balancing.
            </span>
          </p>
        </div>
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-16 md:mt-[50px] md:mb-[0px] lg:mt-[100px] lg:mb-[50px]"></div>
      <div className="flex flex-col md:flex-row lg:flex-row m-5">
        <div className="mb-1 mt-10 md:mt-0 md:mb-8 lg:mt-0 lg:mb-12 ml-18 h-44 w-52 mr-12 lg:h-80 lg:w-[430px] md:h-72 md:w-80 lg:flex md:flex">
          <video
            src={Cloudmigration}
            loading="lazy"effect="blur"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            className="h-full w-full md:h-[300px] md:w-[400px] lg:h-[320px] lg:w-[430px] mobile:md:h-[320px]"
          >
          </video>
        </div>
        <div className=" items-center justify-center  mt-0  md:mt-0 md:mb-0 lg:mt-0 ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-3xl rubik font-bold text-black md:mt-20 lg:mt-20 mb-[20px]">
              Cloud migration
            </h2>
            <p>
              <span className="hidden md:inline">
                Ready to move to the cloud? Our migration experts
                <br />
                ensure a smooth transition of your applications and data,
                <br />
                minimizing downtime and maximizing efficiency.
              </span>
              <span className="inline md:hidden">
                Ready to move to the cloud? Our migration experts ensure a
                smooth transition of your applications and data, minimizing
                downtime and maximizing efficiency.
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 lg:mt-2 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Minimal Downtime</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Migrate without interrupting<br></br> business operations.
            </span>
            <span className="inline md:hidden">
              Migrate without interrupting business operations.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Business Continuity</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Maintain operations without<br></br> interruptions during the
              migration.
            </span>
            <span className="inline md:hidden">
              Maintain operations without interruptions during the migration.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Lower Risk</h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Our experts ensure no data <br></br>loss or misconfiguration.
            </span>
            <span className="inline md:hidden">
              Our experts ensure no data loss or misconfiguration.
            </span>
          </p>
        </div>
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[50px] md:mb-[0px] lg:mt-[100px] lg:mb-[50px]"></div>
      <div className="mt-16 ml-0 h-36 w-48 mr-20 mobile-m:mr-36 mobile-l:mr-48 lg:hidden md:hidden sm:h-64 sm:w-72">
        <video
          src={Cloudsecurity}
          loading="lazy"effect="blur"
          className="h-full w-full rounded-lg"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          preload="metadata"
          alt="Frame Blue"
        />
      </div>

      <div className=" flex m-5">
        <div className=" items-center justify-center ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-3xl rubik font-bold text-black md:mt-20 lg:mt-16 mb-[20px]">
              Cloud security
            </h2>
            <p>
              <span className="hidden md:inline">
                Protect your valuable data with our comprehensive cloud security
                <br />
                solutions. We implement the latest technologies and best
                <br />
                practices to safeguard your information from threats.
              </span>
              <span className="inline md:hidden">
                Protect your valuable data with our comprehensive cloud security
                solutions. We implement the latest technologies and best
                practices to safeguard your information from threats.
              </span>
            </p>
          </div>
        </div>
        <div className="mt-40 ml-8 h-10 lg:mt-0 lg:h-80 lg:w-[400px] md:h-72 md:w-80 md:mt-0 md:mb-9 lg:flex md:flex hidden">
          <video
            src={Cloudsecurity}
            loading="lazy"effect="blur"
            className="h-full w-full"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            alt="cloudsecurity"
          >
          </video>
        </div>

      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Data Protection</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Prevent unauthorized access<br></br> and breaches.
            </span>
            <span className="inline md:hidden">
              Prevent unauthorized access and breaches.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Compliance Ready</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Meet industry regulations effortlessly.
            </span>
            <span className="inline md:hidden">
              Meet industry regulations effortlessly.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Threat Response</h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Get real-time alerts and remediation for potential risks.
            </span>
            <span className="inline md:hidden">
              Get real-time alerts and remediation for potential risks.
            </span>
          </p>
        </div>
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[50px] md:mb-[0px] lg:mt-[100px] lg:mb-[60px]"></div>
      <div className="flex flex-col md:flex-row lg:flex-row m-5">
        <div className="mb-1 mt-10 md:mt-0 lg:mt-0 ml-18 h-40 w-52 mr-12 lg:h-80 lg:w-[400px] md:h-72 md:w-80 lg:flex md:flex">
          <video
            src={Clouddeployment}
            loading="lazy"effect="blur"
            alt="clouddeployment"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            className="h-full w-full"
          >
          </video>
        </div>
        <div className=" items-center justify-center  mt-10 md:mt-0 lg:mt-0 ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-3xl rubik font-bold text-black mt-6 md:mt-16 lg:mt-20 mb-[20px]">
              Cloud deployment
            </h2>
            <p>
              <span className="hidden md:inline">
                Get your applications up and running with our hassle-free
                <br />
                deployment services. We manage everything from setup
                <br />
                to scaling, allowing you to focus on your business.
              </span>
              <span className="inline md:hidden">
                Get your applications up and running with our hassle-free
                deployment services. We manage everything from setup to scaling,
                allowing you to focus on your business.
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 lg:mt-10 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Faster Setup</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Reduce time to deploy apps<br></br> and services.
            </span>
            <span className="inline md:hidden">
              Reduce time to deploy apps and services.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Auto-Scaling</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Adjust resources on-demand during<br></br> traffic spikes.
            </span>
            <span className="inline md:hidden">
              Adjust resources on-demand during traffic spikes.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Error-Free Deployments
          </h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Reduce deployment failures<br></br> with our expertise.
            </span>
            <span className="inline md:hidden">
              Reduce deployment failures with our expertise.
            </span>
          </p>
        </div>
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[50px] md:mb-[0px] lg:mt-[100px] lg:mb-[40px]"></div>
      <div className="mt-16 ml-0 h-32 w-48 mr-20 mobile-m:mr-36 mobile-l:mr-48 lg:hidden md:hidden sm:h-64 sm:w-80">
        <video
          src={Disaster}
          loading="lazy"effect="blur"
          className="h-full w-full "
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          preload="metadata"
          alt="Frame Blue"
        />
      </div>


      <div className=" flex m-5">
        <div className=" items-center justify-center ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-3xl rubik font-bold text-black md:mt-16 lg:mt-12 mb-[10px]">
              Disaster recovery
            </h2>
            <p>
              <span className="hidden md:inline">
                Our disaster recovery solutions help you quickly recover
                <br />
                data and systems during an outage. We develop backup
                <br />
                and recovery plans to ensure your business can quickly
                <br />
                bounce back from any disruption.
              </span>
              <span className="inline md:hidden">
                Our disaster recovery solutions help you quickly recover data
                and systems during an outage. We develop backup and recovery
                plans to ensure your business can quickly bounce back from any
                disruption.
              </span>
            </p>
          </div>
        </div>
        <div className="mt-48 ml-24 h-10 lg:mt-0 md:mt-0 lg:h-80 lg:w-[420px] md:h-72 md:w-80 lg:flex md:flex hidden">
          <video
            src={Disaster}
            loading="lazy"effect="blur"
            className="h-full w-full"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            alt="disaster"
          >
          </video>
        </div>

      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 lg:mt-7 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Rapid Recovery</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Minimize downtime with automated<br></br> backups and recovery
              plans.
            </span>
            <span className="inline md:hidden">
              Minimize downtime with automated backups and recovery plans.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Data Integrity</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Ensure critical data remains intact <br></br>during crises.
            </span>
            <span className="inline md:hidden">
              Ensure critical data remains intact during crises.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Cost-Efficient Failover
          </h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Access backup environments only <br></br>when needed, saving on
              costs.
            </span>
            <span className="inline md:hidden">
              Access backup environments only when needed, saving on costs.
            </span>
          </p>
        </div>
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[50px] md:mb-[0px] lg:mt-[100px] lg:mb-[50px]"></div>
      <div className="flex flex-col md:flex-row lg:flex-row m-5">
        <div className="mb-1 mt-10 md:mt-0 lg:mt-0 ml-18 h-40 w-52 mr-12 lg:h-80 lg:w-[420px] md:h-72 md:w-80 lg:flex md:flex">
          <video
            src={Cloudapplication}
            loading="lazy"effect="blur"
            alt="cloudapplication"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            className="h-full w-full"
          >
          </video>
        </div>
        <div className=" items-center justify-center  mt-10 md:mt-0 lg:mt-0 ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-3xl rubik font-bold text-black mt-6 md:mt-16 lg:mt-14 mb-[10px]">
              <span className="hidden md:inline">
                Cloud Application
                <br />
                Development
              </span>
              <span className="inline md:hidden">
                Cloud Application Development
              </span>
            </h2>
            <p>
              <span className="hidden md:inline">
                Innovate with custom cloud applications tailored to your
                <br />
                needs. Our development team builds scalable, efficient
                <br />
                solutions that drive your business forward.
              </span>
              <span className="inline md:hidden">
                Innovate with custom cloud applications tailored to your needs.
                Our development team builds scalable, efficient solutions that
                drive your business forward.
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 lg:mt-10 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Custom Solutions</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Build applications tailored to your exact needs.
            </span>
            <span className="inline md:hidden">
              Build applications tailored to your exact needs.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Lower Development Costs
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Leverage cloud infrastructure to cut<br></br> development costs.
            </span>
            <span className="inline md:hidden">
              Leverage cloud infrastructure to cut development costs.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Global Accessibility</h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Ensure your apps are available from<br></br> anywhere, on any
              device.
            </span>
            <span className="inline md:hidden">
              Ensure your apps are available from anywhere, on any device.
            </span>
          </p>
        </div>
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[50px] md:mb-[0px] lg:mt-[100px] lg:mb-[40px]"></div>
      <div className="mt-16 ml-6 h-36 w-56 mr-20 mobile-m:mr-36 mobile-l:mr-44 lg:hidden md:hidden">
        <video src={Cloudmonitor} loading="lazy"effect="blur" alt="Frame Blue"   autoPlay
        loop
        muted
        playsInline
        webkit-playsinline="true"
        preload="metadata"
        className="h-full w-full" />
      </div>
      <div className=" flex m-5">
        <div className=" items-center justify-center ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-3xl rubik font-bold text-black md:mt-20 lg:mt-10 mb-[10px]">
              Cloud monitoring
            </h2>
            <p>
              <span className="hidden md:inline">
                Stay ahead of potential issues with our proactive
                <br />
                monitoring services. We provide real-time insights and
                <br />
                analytics, ensuring your cloud environment is always
                <br />
                performing at its best.
              </span>
              <span className="inline md:hidden">
                Stay ahead of potential issues with our proactive monitoring
                services. We provide real-time insights and analytics, ensuring
                your cloud environment is always performing at its best.
              </span>
            </p>
          </div>
        </div>
        <div className="mt-48 ml-24 h-10 lg:mt-0 md:mt-0 lg:h-80 lg:w-[420px] md:h-72 md:w-80 lg:flex md:flex hidden">
          <video
            src={Cloudmonitor}
            loading="lazy"effect="blur"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            className="w-full h-full"
          >
          </video>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 lg:mt-7 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Proactive Maintenance
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Identify and address issues before they impact users.
            </span>
            <span className="inline md:hidden">
              Identify and address issues before they impact users.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Performance Optimization
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Adjust workloads dynamically based on real-time insights.
            </span>
            <span className="inline md:hidden">
              Adjust workloads dynamically based on real-time insights.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Billing Transparency</h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Monitor usage to avoid <br></br>surprise costs
            </span>
            <span className="inline md:hidden">
              Monitor usage to avoid surprise costs
            </span>
          </p>
        </div>
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[50px] md:mb-[0px] lg:mt-[100px] lg:mb-[50px]"></div>
      <div className="flex flex-col md:flex-row lg:flex-row m-5">
        <div className="mb-1 mt-10 md:mt-0 md:mb-0 lg:mt-0 lg:mb-0 ml-18 h-28 w-48 mr-12 lg:h-80 lg:w-[420px] md:h-72 md:w-80 lg:flex md:flex sm:h-36 sm:w-52">
          <video
            src={Dataanalytics}
            className="h-full w-full "
            autoPlay
            loading="lazy"effect="blur"
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            alt="data"
          />
        </div>
        <div className=" items-center justify-center  mt-10 md:mt-0 lg:mt-0 ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-3xl rubik font-bold text-black mt-6 md:mt-16 lg:mt-10 mb-[10px]">
              Data Analytics and BI
            </h2>
            <p>
              <span className="hidden md:inline ">
                With advanced analytics and BI tools transform raw data
                <br />
                into actionable insights. We help you identify patterns,
                <br />
                measure performance, and make smarter, data-driven
                <br />
                decisions in real time.
              </span>
              <span className="inline md:hidden">
                With advanced analytics and BI tools transform raw data into
                actionable insights. We help you identify patterns, measure
                performance, and make smarter, data-driven decisions in real
                time.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 md:gap-24 lg:gap-28 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Data-Driven Strategies
          </h3>
          <p className="text-black-400 font-jakarta">
            Use analytics to guide<br></br> decision-making.
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Interactive Dashboards
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Visualize complex data for<br></br> quick insights.
            </span>
            <span className="inline md:hidden">
              Visualize complex data for quick insights.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Visualized Reports</h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              Generate dashboards for easy<br></br> data interpretation.
            </span>
            <span className="inline md:hidden">
              Generate dashboards for easy data interpretation.
            </span>
          </p>
        </div>
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[50px] md:mb-[0px] lg:mt-[100px] lg:mb-[50px]"></div>
      <div className="mt-16 ml-0 h-44 w-48 mr-20 mobile-m:mr-40 mobile-l:mr-48 lg:hidden md:hidden">
        <video
          src={Twentyfour}
          loading="lazy"effect="blur"
          alt="Frame Blue"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          preload="metadata"
          className=" w-full object-cover"
        >
        </video>
      </div>
      <div className=" flex m-5">
        <div className=" items-center justify-center ">
          <div className="text-black font-jakarta text-left md:text-xs lg:text-lg lg:text-left md:text-left">
            <h2 className="text-2xl md:text-3xl lg:text-3xl rubik font-bold text-black md:mt-20 lg:mt-20 mb-[20px]">
              24/7 support
            </h2>
            <p>
              <span className="hidden md:inline ">
                Our round-the-clock support ensures your cloud solutions
                <br />
                are always running smoothly, so you can focus on your
                <br />
                business without interruptions.
              </span>
              <span className="inline md:hidden">
                Our round-the-clock support ensures your cloud solutions are
                always running smoothly, so you can focus on your business
                without interruptions.
              </span>
            </p>
          </div>
        </div>
        <div className="mt-48 ml-24 h-10 lg:mt-0 md:mt-0 lg:h-72 lg:w-80 md:h-64 md:w-64 lg:flex md:flex hidden">
          <video
            src={Twentyfour}
            alt="twentyfour"
            loading="lazy"effect="blur"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline="true"
            preload="metadata"
            className="h-full w-full"
          >
          </video>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-0 lg:mt-8 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Continuous Availability
          </h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Get help whenever<br></br>you need it.
            </span>
            <span className="inline md:hidden">
              Get help whenever you need it.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Swift Resolution</h3>
          <p className="text-black-400 font-jakarta">
            <span className="hidden md:inline">
              Minimize downtime with<br></br> quick responses.
            </span>
            <span className="inline md:hidden">
              Minimize downtime with quick responses.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            loading="lazy"effect="blur"
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">Proactive Assistance</h3>
          <p className="text-left md:text-center lg:text-center font-jakarta">
            <span className="hidden md:inline">
              We identify and resolve potential issues before they escalate.
            </span>
            <span className="inline md:hidden">
              We identify and resolve potential issues before they escalate.
            </span>
          </p>
        </div>
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[100px] lg:mt-[100px]"></div>
      <div className="text-center mt-[50px] md:mt-[100px] lg:mt-[100px] mb-[20px] pb-5">
        <h3
          className="rubik text-4xl font-bold leading-[48px] text-center"
          style={{ color: "#000000" }}
        >
          Why are Managed services essential?
        </h3>
        <h3 className="font-normal mt-2 font-jakarta" style={{ color: "#222222" }}>
          At Cloudevolve, your security is our top priority. Our advanced tools
          offer:
        </h3>

        <div className="relative flex flex-col justify-center items-center">
          {/* Horizontal Line */}
          <div className="absolute xl:top-[220px] lg:top-[240px] lg:ml-36 md:top-60 md:ml-2 lg:left-0 md:left-0 xl:w-[950px] md:w-[750px] xl:ml-36 lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4">
            {" "}
          </div>
          <div className="absolute xl:top-[410px] lg:top-[447px] lg:ml-36 md:top-[425px] md:ml-2 lg:left-0 md:left-0 xl:w-[950px] md:w-[750px] xl:ml-36 lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4">
            {" "}
          </div>
          {/* Vertical Line */}
          <div className="absolute lg:left-1/2 md:left-1/2 lg:top-4 md:top-5 lg:h-[630px] md:h-[640px] w-0.5 bg-gray-25">
            {" "}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-40 gap-y-8 mt-12 px-4 lg:px-40">
            {cardData.map((card, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center w-full"
              >
                <div className="h-16 w-16 flex items-center justify-center rounded">
                  <img src={card.icon} loading="lazy"effect="blur" alt={card.alt} className="w-14 h-14" />
                </div>
                <h4 className="text-lg font-semibold mt-4 rubik">
                  {" "}
                  {card.title}{" "}
                </h4>
                <p
                  className="text-sm text-black font-jakarta mt-2"
                  style={{ color: "#222222", maxWidth: "380px" }}
                >
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-14 flex items-center justify-center">
        <h2 className="text-2xl font-bold text-black">Our Partners</h2>
      </div>


      <div className="flex flex-col md:flex-row md:justify-center items-center gap-14 p-4 mt-8">
        <img
          src={Microsoft}
          alt="microsoft 1"
          loading="lazy"effect="blur"
          className="w-32 md:w-1/4 min-w-sm "
        />
        <img src={Aws} loading="lazy"effect="blur" alt="aws 2" className="w-24 h-16 md:w-24 min-w-sm " />
        <img src={Google} loading="lazy"effect="blur" alt="google 3" className="w-32 md:w-1/4 min-w-sm " />
      </div>
    </div>
  );
};

export default Cloudmanagedservices;
