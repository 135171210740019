import React, { useState, useEffect } from "react";
import search from '../assets/search.webp';
import plus from '../assets/plus.webp';
import xmark from '../assets/xmark.webp';
import { Helmet } from "react-helmet-async";

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedTag, setSelectedTag] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const toggleFaq = (index) => {
    // Close the currently open FAQ if the same index is clicked
    setOpenIndex(openIndex === index ? null : index);
  };

  const tagsData = ["All", "Cloud cost", "AWS", "GCP", "Kubernetes", "Snowflake"];

  const faqs = [
    {
      question: "Are there any commitments required?",
      answer: "No, we believe in flexibility! You can enjoy savings without any long-term commitments.",
      tags: ["Cloud cost", "AWS"],
    },
    {
      question: "Is there any fee to join?",
      answer: "No, there is no fee to join.",
      tags: ["GCP", "Kubernetes"],
    },
    {
      question: "What makes Cloudevolve different from other platforms?",
      answer: "Cloudevolve offers competitive pricing and seamless integration.",
      tags: ["Snowflake", "Cloud cost"],
    },
    {
      question: "How often does pricing adjust to usage changes?",
      answer: "Pricing is adjusted based on your usage every month.",
      tags: ["AWS", "Kubernetes"],
    },
    {
      question: "Can I access detailed reports on my spending?",
      answer: "Yes, you can access detailed spending reports through the dashboard.",
      tags: ["GCP", "Snowflake"],
    },
    {
      question: "What happens if my cloud usage changes?",
      answer: "Your pricing adjusts automatically to reflect your current usage.",
      tags: ["Cloud cost"],
    },
    {
      question: "Are there any hidden fees?",
      answer: "No, we maintain complete transparency with our pricing.",
      tags: ["AWS", "Snowflake"],
    },
  ];

  const handleChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredFaqs = faqs.filter((faq) => {
    const matchesTag = selectedTag === "All" || faq.tags.includes(selectedTag);
    const matchesSearch =
      faq.question.toLowerCase().includes(searchQuery) ||
      faq.tags.some((tag) => tag.toLowerCase().includes(searchQuery));
    return matchesTag && matchesSearch;
  });

  // Reset openIndex when searchQuery or selectedTag changes
  useEffect(() => {
    setOpenIndex(null);
  }, [searchQuery, selectedTag]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-6 mt-12 max-w-4xl mx-auto">
      <Helmet prioritizeSeoTags>
        <title>FAQ  | Cloudevolve | Find quick answers to your cloud savings questions. Browse Cloudevolve’s FAQs to learn how we simplify AWS cost management for you. </title>
        <link
          rel="Important"
          href="https://cloudevolve.com/Faq"
        />
        <meta name="description" content="Find quick answers to your cloud savings questions. Browse Cloudevolve’s FAQs to learn how we simplify AWS cost management for you." />
        <meta name="keywords" content="Cloudevolve, cloud cost savings, cloud solutions, innovation, AWS cost management" />
        <meta name="author" content="Cloudevolve" />

        <link rel="preload" as="video" href="/video.mp4" type="video/mp4" />
      </Helmet>

      {/* Header */}
      <h1 className="text-2xl md:text-4xl lg:text-4xl font-bold text-center mb-2 mt-6 rubik">FAQ</h1>
      <p className="text-center mb-8 font-jakarta">Answer to all your questions</p>

      {/* Search Bar */}
      <div className="mb-6 mx-auto" style={{ maxWidth: "580px" }}>
        <div
          className="flex items-center border border-gray-300 rounded-lg"
          style={{
            padding: "5px",
          }}
        >
          <img
            src={search}
            alt="Search Icon"
            loading="lazy"effect="blur"
            className="w-5 h-5 mx-2"
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          <input
            type="text"
            onChange={handleChange}
            placeholder="Search topic here"
            className="flex-1 py-1 px-2"
            style={{
              border: "none",
              outline: "none",
              color: "#808080",
            }}
          />
        </div>
      </div>

      {/* Tags */}
      <div className="flex gap-2 mb-12 flex-wrap justify-center">
        {tagsData.map((tag, idx) => (
          <button
            key={idx}
            style={
              selectedTag === tag
                ? { border: "2px solid black", fontWeight: "bold", color: "black", backgroundColor: "transparent", borderRadius: '8px' }
                : { border: "1px solid #B2B2B2", borderRadius: '8px' }
            }
            className="px-4 py-1 rounded-lg text-sm rubik"
            onClick={() => setSelectedTag(tag)}
          >
            {tag}
          </button>
        ))}
      </div>

      {/* FAQ Items */}
      <div>
        {filteredFaqs.map((faq, index) => (
          <div key={index} className="border-b py-4">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleFaq(index)}
            >
              <h2 className="font-bold font-jakarta">{faq.question}</h2>
              <span>
                {openIndex === index ? (
                  <img src={xmark} alt="Close" loading="lazy"effect="blur" className="w-5 h-5" />
                ) : (
                  <img src={plus} alt="Open" loading="lazy"effect="blur" className="w-5 h-5" />
                )}
              </span>
            </div>
            {openIndex === index && <p className="mt-2 font-jakarta">{faq.answer}</p>}
          </div>
        ))}
        {filteredFaqs.length === 0 && (
          <p className="text-center text-gray-500 mt-4">
            No FAQs available for this tag or search query.
          </p>
        )}
      </div>
    </div>
  );
};

export default Faq;
