import React from "react";
import "./ResponsiveHero.css";

import MobileHeroAnimation from '../assets/mobile hero animation 1.gif'
const ResponsiveHero = ({ Hero, HeroMobile, rightsidearrow, navigate }) => {
  return (
    <div className="relative mt-[5rem] lg:mt-40 w-full min-h-screen  bg-white">
      {/* Video Background */}
      <div className="absolute inset-0 w-full h-screen">
        {/* Desktop Video */}
        <video
          src={Hero}
          loading="lazy"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          preload="metadata"
          className="hidden md:block w-full h-[calc(100vh-80px)] object-fill"
        />

        {/* Mobile Video */}
        <img
          src={MobileHeroAnimation}
          loading="lazy"
          alt="mobile"
          // autoPlay
          // loop
          // muted
          // playsInline
          webkit-playsinline="true"
          preload="metadata"
          className="block md:hidden w-full h-full 2xl:h-full object-fill"
        />
      </div>

      {/* Content Container */}
      <div className="relative z-10 w-full h-screen flex flex-col items-center">
        {/* Text Container */}
        <div
          className="text-center px-4 max-w-7xl mx-auto
         responsivehero"
        >
          {/* <h1
            className="font-rubik font-bold text-black mb-2
           heading-size
            "
          >
            Jet. Set. Free.
          </h1>
          <p
            className="font-jakarta text-black mx-auto
           para-font"
          >
            Discover ways to optimize your AWS costs without purchasing a{" "}
            <br></br>
            savings plan or making any reservations on your end.
          </p> */}
        </div>

        {/* Schedule Button */}
        <div
          className="absolute w-full
         schbutton"
        >
          <button
            onClick={() => navigate("/bookyour")}
            className="relative flex items-center justify-center gap-2 mx-auto text-white w-24 h-20 md:w-60 md:h-28"
          >
            <span className="absolute inset-0"></span>
            <span className="font-jakarta font-bold schfont"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveHero;
