import React, { useEffect, useRef, useState } from 'react';
import test from '../assets/test.webp';
import Test1 from '../assets/test1.webp';
import blg from "../assets/blgg.webp";
import { Helmet } from "react-helmet-async";





const Testimonials = () => {


    const blogs13 = [
        {
            content: "Our AWS bills were skyrocketing until we discovered Cloudevolve. Their platform cut our costs significantly without any upfront commitments. Truly a game-changer for our business!",
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "Cloudevolve makes cloud savings effortless. We saved over 50% on our cloud expenses, and their insights helped us optimize resource utilization like never before.",
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "What sets Cloudevolve apart is their risk-free approach to savings. We saw tangible benefits within weeks!",
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "Their Cloud 360 Intelligence Platform provided unparalleled visibility into our AWS spending. The detailed analytics helped us stay on top of every dollar spent.",
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "From cost optimization to real-time insights, Cloudevolve has become an indispensable partner in our cloud journey. Highly recommended for any AWS user!",
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "Joining Cloudevolve was the best decision for our cloud management strategy. It's like having a personal cloud savings expert on your team.",
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },

    ];

    const blogs12 = [
        {
            content: "Our AWS bills were skyrocketing until we discovered Cloudevolve. Their platform cut our costs significantly without any upfront commitments. Truly a game-changer for our business!",
            author: "ABC",
            role: "Web Designer",
            image: Test1,
        },
        {
            content: "Cloudevolve makes cloud savings effortless. We saved over 50% on our cloud expenses, and their insights helped us optimize resource utilization like never before.",
            author: "ABC",
            role: "Web Designer",
            image: test,
        },
        {
            content: "What sets Cloudevolve apart is their risk-free approach to savings. We saw tangible benefits within weeks!",
            author: "ABC",
            role: "Web Designer",
            image: Test1,
        },
        {
            content: "Their Cloud 360 Intelligence Platform provided unparalleled visibility into our AWS spending. The detailed analytics helped us stay on top of every dollar spent.",
            author: "ABC",
            role: "Web Designer",
            image: test,
        },
        {
            content: "From cost optimization to real-time insights, Cloudevolve has become an indispensable partner in our cloud journey. Highly recommended for any AWS user!",
            author: "ABC",
            role: "Web Designer",
            image: Test1,
        },
        {
            content: "Joining Cloudevolve was the best decision for our cloud management strategy. It's like having a personal cloud savings expert on your team.",
            author: "ABC",
            role: "Web Designer",
            image: test,
        },
        {
            content: "Our AWS bills were skyrocketing until we discovered Cloudevolve. Their platform cut our costs significantly without any upfront commitments. Truly a game-changer for our business!",
            author: "ABC",
            role: "Web Designer",
            image: Test1,
        },
        {
            content: "Cloudevolve makes cloud savings effortless. We saved over 50% on our cloud expenses, and their insights helped us optimize resource utilization like never before.",
            author: "ABC",
            role: "Web Designer",
            image: test,
        },
        {
            content: "What sets Cloudevolve apart is their risk-free approach to savings. We saw tangible benefits within weeks!",
            author: "ABC",
            role: "Web Designer",
            image: Test1,
        },
        {
            content: "Their Cloud 360 Intelligence Platform provided unparalleled visibility into our AWS spending. The detailed analytics helped us stay on top of every dollar spent.",
            author: "ABC",
            role: "Web Designer",
            image: test,
        },
        {
            content: "From cost optimization to real-time insights, Cloudevolve has become an indispensable partner in our cloud journey. Highly recommended for any AWS user!",
            author: "ABC",
            role: "Web Designer",
            image: Test1,
        },
        {
            content: "Joining Cloudevolve was the best decision for our cloud management strategy. It's like having a personal cloud savings expert on your team.",
            author: "ABC",
            role: "Web Designer",
            image: test,
        },

    ];

    const duplicatedBlogs12 = Array.from({ length: 6 }, () => blogs12).flat();
    const duplicatedBlogs13 = Array.from({ length: 6 }, () => blogs13).flat();





    const scrollRef = useRef(null);
    const [isUserScrolling, setIsUserScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsUserScrolling(true);
            clearTimeout(window.scrollTimeout);
            window.scrollTimeout = setTimeout(() => {
                setIsUserScrolling(false); // Restart animation after user stops scrolling
            }, 3000);
        };

        const scrollContainer = scrollRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
            return () => scrollContainer.removeEventListener("scroll", handleScroll);
        }
    }, []);






    const scrollRef2 = useRef(null);
    const [isUserScrolling2, setIsUserScrolling2] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsUserScrolling2(true);
            clearTimeout(window.scrollTimeout2);
            window.scrollTimeout2 = setTimeout(() => {
                setIsUserScrolling2(false); // Restart animation after user stops scrolling
            }, 3000);
        };

        const scrollContainer = scrollRef2.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
            return () => scrollContainer.removeEventListener("scroll", handleScroll);
        }
    }, []);



    const scrollRef3 = useRef(null);
    const [isUserScrolling3, setIsUserScrolling3] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsUserScrolling3(true);
            clearTimeout(window.scrollTimeout3);
            window.scrollTimeout3 = setTimeout(() => {
                setIsUserScrolling3(false); // Restart animation after user stops scrolling
            }, 3000);
        };

        const scrollContainer = scrollRef3.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
            return () => scrollContainer.removeEventListener("scroll", handleScroll);
        }
    }, []);



    return (
        <div className="text-center mt-24 mb-0 pb-0">
            <Helmet prioritizeSeoTags>
                <title>Testimonials   | Cloudevolve | Trusted by businesses worldwide—explore from Cloudevolve users who’ve achieved incredible AWS savings and cloud efficiency.  </title>
                <link
                    rel="Important"
                    href="https://cloudevolve.com/Testimonials"
                />
                <meta name="description" content="Trusted by businesses worldwide—explore from Cloudevolve users who’ve achieved incredible AWS savings and cloud efficiency. " />
                <meta name="keywords" content="Cloudevolve, cloud cost savings, cloud solutions, innovation, AWS cost management" />
                <meta name="author" content="Cloudevolve" />
            </Helmet>
            <h3 className="text-4xl rubik font-bold" style={{ color: "#000000" }}>
                Testimonials
            </h3>
            <h3 className="font-jakarta mt-2" style={{ color: "#222222" }}>
                Hear it from those who save big with us.

            </h3>


            <div className="overflow-x-auto whitespace-normal">
                <div
                    ref={scrollRef3} // Correct ref used here
                    className="relative overflow-x-auto w-full mt-14"
                    style={{
                        scrollBehavior: "smooth",
                        overflowX: "scroll",
                        WebkitOverflowScrolling: "touch",
                        willChange: "transform",
                    }}
                >
                    <div className={`flex gap-4 ${!isUserScrolling3 ? "animate-scroll-left-to-right" : ""}`}>
                        {duplicatedBlogs13.concat(duplicatedBlogs13).map((blog, index) => (
                            <div
                                key={index}
                                className="w-[280px] h-[360px] md:w-[70%] md:h-[350px] lg:w-[90%] lg:h-[320px] max-w-2xl p-2 grid md:grid-cols-2 lg:grid-cols-2 items-stretch rounded-2xl shadow-lg mb-5 snap-start"
                                style={{
                                    flex: "0 0 auto",
                                    backgroundColor: "#ffff",
                                    border: "1px solid black",
                                    backgroundImage: `url(${blog.image})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            >
                                <div className="flex flex-col justify-between p-0">
                                    <p className="text-lg md:text-lg lg:text-xl mb-4 text-left rubik font-normal ml-4 lg:ml-5 lg:mt-5 md:ml-5 md:mt-5"
                                        style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                        {blog.content}
                                    </p>
                                    <div className="flex">
                                        <div className="flex justify-start items-center md:hidden lg:hidden">
                                            <img src={blog.images} alt={blog.author} className="w-24 h-24 ml-4 object-contain block sm:hidden" loading="lazy" />
                                        </div>
                                        <h3 className="flex justify-start ml-6 md:ml-4 lg:ml-4 mt-5 md:justify-end lg:justify-end font-jakarta font-semibold text-lg md:text-base lg:text-base lg:mr-8 md:mr-8 lg:mb-5 md:mb-5"
                                            style={{ color: "#222222" }}>
                                            - {blog.author}, {blog.role}
                                        </h3>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center">
                                    <img src={blog.images} alt={blog.author} className="w-40 md:w-56 lg:w-64 h-auto hidden md:flex lg:flex" loading="lazy" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>







                <div
                    ref={scrollRef2}
                    className="overflow-x-auto whitespace-normal mt-14 w-full"
                    style={{
                        scrollBehavior: "smooth",
                        msOverflowStyle: "none", /* IE and Edge */
                        scrollbarWidth: "none",   /* Firefox */
                        WebkitOverflowScrolling: "touch", /* Better touch scrolling */
                        scrollSnapType: "x mandatory", /* Smooth card alignment */
                        willChange: "transform",
                    }}
                >
                    <style jsx>{`
            div::-webkit-scrollbar {
                display: none; /* Chrome, Safari, Opera */
            }
        `}</style>
                    <div className={`flex gap-4 ${!isUserScrolling2 ? "animate-infinite-scroll" : ""}`}>
                        {duplicatedBlogs12.map((blog, index) => (
                            <div
                                key={index}
                                className="w-[280px] left-0 h-[360px] lg:w-[90%] lg:h-[320px] md:w-[70%] md:h-[350px] flex-shrink-0 max-w-2xl p-2 grid md:grid-cols-2 lg:grid-cols-2 items-stretch rounded-2xl shadow-lg mb-5"
                                style={{
                                    flex: "0 0 auto",
                                    backgroundColor: "#ffff",
                                    border: "1px solid black",
                                    backgroundImage: `url(${blg})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            >
                                <div className="flex flex-col justify-between p-1">
                                    <p className="text-lg md:text-lg lg:text-xl mb-4 text-left rubik font-normal ml-4 lg:ml-5 lg:mt-5 md:ml-5 md:mt-5">
                                        {blog.content}
                                    </p>
                                    <div className="flex">
                                        <div className="flex justify-start items-center md:hidden lg:hidden">
                                            <img
                                                src={blog.image}
                                                alt={blog.author}
                                                className="w-24 h-24 ml-4 object-contain block sm:hidden"
                                            />
                                        </div>
                                        <div>
                                            <h3 className="flex justify-start ml-12 mt-5 md:justify-end lg:justify-end rubik font-semibold text-lg text-left md:text-left lg:text-left md:text-base lg:text-base lg:ml-2 md:ml-4 lg:mr-20 md:mr-8 lg:mb-5 md:mb-5">
                                                - {blog.author}, {blog.role}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center">
                                    <img
                                        src={blog.image}
                                        alt={blog.author}
                                        className="w-64 h-64 hidden md:flex lg:flex"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>






                <div
                    ref={scrollRef}
                    className="relative overflow-x-auto w-full mt-14"
                    style={{
                        scrollBehavior: "smooth",
                        overflowX: "scroll",
                        WebkitOverflowScrolling: "touch",
                        willChange: "transform",
                    }}
                >
                    {/* Always keep gap-4 to prevent spacing changes when scrolling */}
                    <div className={`flex gap-4 ${!isUserScrolling ? "animate-scroll-left-to-right" : ""}`}>
                        {duplicatedBlogs13.concat(duplicatedBlogs13).map((blog, index) => (
                            <div
                                key={index}
                                className="w-[280px] h-[360px] md:w-[70%] md:h-[350px] lg:w-[90%] lg:h-[320px] max-w-2xl p-2 grid md:grid-cols-2 lg:grid-cols-2 items-stretch rounded-2xl shadow-lg mb-5 snap-start"
                                style={{
                                    flex: "0 0 auto",
                                    backgroundColor: "#ffff",
                                    border: "1px solid black",
                                    backgroundImage: `url(${blog.image})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            >
                                <div className="flex flex-col justify-between p-0">
                                    <p className="text-lg md:text-lg lg:text-xl mb-4 text-left rubik font-normal ml-4 lg:ml-5 lg:mt-5 md:ml-5 md:mt-5"
                                        style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                        {blog.content}
                                    </p>
                                    <div className="flex">
                                        <div className="flex justify-start items-center md:hidden lg:hidden">
                                            <img src={blog.images} alt={blog.author} className="w-24 h-24 ml-4 object-contain block sm:hidden" loading="lazy" />
                                        </div>
                                        <h3 className="flex justify-start ml-6 md:ml-4 lg:ml-4 mt-5 md:justify-end lg:justify-end font-jakarta font-semibold text-lg md:text-base lg:text-base lg:mr-8 md:mr-8 lg:mb-5 md:mb-5"
                                            style={{ color: "#222222" }}>
                                            - {blog.author}, {blog.role}
                                        </h3>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center">
                                    <img src={blog.images} alt={blog.author} className="w-40 md:w-56 lg:w-64 h-auto hidden md:flex lg:flex" loading="lazy" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Testimonials;