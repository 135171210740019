import React, { useState } from "react";
import base from "../assets/Base Illustration.webp";
import heroimage from "../assets/hero image.png";
// import crew1 from "../assets/case study/Most-Famous-Logos-in-BlackWhite 3.png";
// import crew2 from "../assets/case study/Most-Famous-Logos-in-BlackWhite 4.png";
// import crew3 from "../assets/case study/Most-Famous-Logos-in-BlackWhite 5.png";
// import crew4 from "../assets/case study/Most-Famous-Logos-in-BlackWhite 6.png";
// import crew5 from "../assets/case study/Most-Famous-Logos-in-BlackWhite 7.png";
// import crew6 from "../assets/case study/Most-Famous-Logos-in-BlackWhite 8.png";
// import crew7 from "../assets/case study/Most-Famous-Logos-in-BlackWhite 9.png";
import democardimage from "../assets/Rectangle 38.png";
// import arrowcircleright from "../assets/case study/arrow-circle-right.png";
import readmoreicon from "../assets/Readmoreicon.png";
import { useNavigate } from "react-router-dom";
import Novagems from "../assets/Novagems-logo.webp";
import Lenskart from "../assets/Lenskart-logo.webp";
import Jubna from "../assets/jubna-logo.webp";
import Fitelo from "../assets/Fitelo-Logo.webp";
import Witzeal from "../assets/witzeal-logo.webp";
import Steller from "../assets/stellarinfo-logo.webp";
import People from "../assets/Peoplestrong-logo.webp";
import Outleap from "../assets/Outleap GeeBee-logo.webp";

const caseStudies = [
  {
    id: 1,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "Cloud cost",
    img: democardimage,
  },
  {
    id: 2,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "GCP",
    img: democardimage,
  },
  {
    id: 3,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "AWS",
    img: democardimage,
  },
  {
    id: 4,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "AWS",
    img: democardimage,
  },
  {
    id: 5,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "GCP",
    img: democardimage,
  },
  {
    id: 6,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "Snowflake",
    img: democardimage,
  },
  {
    id: 7,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "Snowflake",
    img: democardimage,
  },
  {
    id: 8,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "Kubernetes",
    img: democardimage,
  },
  {
    id: 9,
    title:
      "The Impact of Technology on the Workplace: How Technology is Changing",
    category: "Kubernetes",
    img: democardimage,
  },
];

// const clientLogos = [
//   Novagems,
//   Lenskart,
//   Jubna,
//   Fitelo,
//   Witzeal,
//   Steller,
//   People,
//   Outleap,
// ];

const categories = [
  "All",
  "Cloud cost",
  "AWS",
  "GCP",
  "Kubernetes",
  "Snowflake",
  "Cloud cost",
  "AWS",
  "GCP",
  "Kubernetes",
  "Snowflake",
  "Cloud cost",
  "AWS",
  "GCP",
];

const CaseStudiesPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  // const [currentSlide, setCurrentSlide] = useState(0);  
  const navigate = useNavigate();

  const filteredStudies =
    selectedCategory === "All"
      ? caseStudies
      : caseStudies.filter((study) => study.category === selectedCategory);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  // const goToSlide = (index) => {
  //   setCurrentSlide(index);
  // };

  const displayedStudies = filteredStudies.filter((study) =>
    study.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // const carouselImages = [democardimage, democardimage, democardimage]; // Replace with your images

  // const nextSlide = () => {
  //   setCurrentSlide((prev) => (prev + 1) % carouselImages.length);
  // };

  // const prevSlide = () => {
  //   setCurrentSlide((prev) =>
  //     prev === 0 ? carouselImages.length - 1 : prev - 1
  //   );
  // };
  return (
    <div className="flex flex-col items-center w-full font-rubik">
      {/* Hero Section */}
      <div className="w-full relative overflow-hidden">
        {/* Background Section */}
        <div className="bg-[#02AAE2] text-white flex flex-col md:flex-row justify-center items-center px-4 py-8 md:px-12 md:py-12 rounded-b-3xl h-[620px] md:h-[550px] lg:h-[650px] relative">
          {" "}
          {/* Text Section */}
          <div className="max-w-lg text-center justify-center md:text-left z-10 space-y-4 md:mr-8 md:-mt-52">
            <span className="bg-white text-[#000000] px-5 py-3 rounded-md  text-lg font-semibold">
              Case Studies
            </span>
            <h1 className="text-3xl md:text-4xl font-bold mt-10 md:mt-32">
              Top 10 Margin-Improving FinOps KPIs: A Technical Perspective on
              Cloud Cost Optimization
            </h1>
            <button className="px-5 py-3 md:mt-10 text-white text-lg font-semibold rounded-lg shadow-md border border-white bg-transparent flex items-center hover:bg-white hover:text-black transition mx-auto md:mx-0">
              <span className="mr-2">Read More</span>
              <img
                src={readmoreicon}
                alt="readmoreicon"
                loading="lazy"effect="blur"
                className="w-[8.333333px] h-[8.333333px] object-cover"
              />
            </button>
          </div>
          {/* Hero Image (Right Side) */}
          <div className="z-10 flex justify-center md:justify-end w-full md:w-auto mt-8 md:-mt-48">
            <img
              src={heroimage}
              alt="Hero"
              loading="lazy"effect="blur"
              className="
      w-full object-contain
      min-w-[150px] max-w-[250px]
      md:min-w-[300px] md:max-w-[400px]
      lg:min-w-[350px] lg:max-w-[450px]
      xl:min-w-[400px] xl:max-w-[500px]
      2xl:min-w-[500px] 2xl:max-w-[600px]
    "
            />
          </div>
        </div>

        {/* Overlapping Wave Image */}
        <img
          src={base}
          alt="Hero Illustration"
          loading="lazy"effect="blur"
          className="absolute bottom-0 left-0 right-0 z-0 object-contain"
        />
      </div>

      {/* Client Logos Section */}
      <div className="mt-8 md:-mt-0 lg:mt-2 2xl:mt-16  md:block">
      <h1 className="flex justify-center font-rubik items-center font-bold text-3xl 
  mobile-m:max-[-3rem] mobile-l:max-[-3.5rem] sm:max-[-4rem] md:max-[-6rem] lg:max-[-6rem] xl:mt-0 mb-4">
  The Client Crew
</h1>


        {/* Top row logos */}
        <div className="flex flex-wrap justify-center gap-8 items-center">
          <img
            src={Novagems}
            alt="Novagems Logo"loading="lazy"effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Lenskart}
            alt="Lenskart Logo"loading="lazy"effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Jubna}
            alt="Jubna Logo"loading="lazy"effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Fitelo}
            alt="Fitelo Logo"loading="lazy"effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Witzeal}
            alt="Witzeal Logo"loading="lazy"effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
        </div>

        {/* Bottom row logos */}
        <div className="flex flex-wrap justify-center gap-10 items-center mt-6">
          <img
            src={Outleap}
            alt="Outleap Logo"loading="lazy"effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Steller}
            alt="Steller Logo"loading="lazy"effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={People}
            alt="People Logo"loading="lazy"effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
        </div>
      </div>

      {/* Horizontal Line */}
      <hr className="border-t-8 md:mt-20 border-[#F4F4F4] my-8 mx-auto w-full" />

      {/* Case Studies Section */}
      <div className="w-full mt-20 flex flex-col items-center px-4 py-8">
        <h1 className="text-3xl md:text-[2.5rem] font-bold text-[#222222] text-center">
          Our Case Studies
        </h1>
        <p className="text-[#222222] text-lg md:text-[1.5rem] text-center mt-5 font-jakarta">
          Our favourite choices
        </p>

        {/* Search Bar */}
        <div className="w-full flex justify-center mt-14">
          <div className="relative w-full max-w-xl">
            <input
              type="text"
              placeholder="Search topic here"
              value={searchQuery}
              onChange={handleSearch}
              className="w-full px-10 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <svg
              className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M10 2a8 8 0 105.293 14.707l4.147 4.146a1 1 0 001.414-1.414l-4.146-4.147A8 8 0 0010 2zm0 2a6 6 0 110 12A6 6 0 0110 4z" />
            </svg>
          </div>
        </div>

        {/* Category Filters */}
        <div className="flex flex-wrap justify-center max-w-6xl gap-2 mt-6">
          {categories.map((category) => (
            <button
              key={category}
              className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                selectedCategory === category
                  ? "bg-white text-black border border-[#000000] shadow-md"
                  : "bg-white text-black border border-gray-300 hover:border-[#000000] hover:shadow-md"
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Case Study Cards */}
        <div
          className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-6xl mt-6 
             cursor-pointer"
          onClick={() => navigate("/platform/Case-Studies-Detail-Page")}
        >
          {displayedStudies.map((study) => (
            <div
              key={study.id}
              className="bg-white border border-black rounded-xl overflow-hidden"
            >
              <img
                src={study.img}
                alt={study.title}
                loading="lazy"effect="blur"
                className="w-full aspect-square h-56 object-cover"
              />
              <div className="p-4">
                <h3 className="font-medium">{study.title}</h3>
                <span className="text-sm bg-[#E1F5FC] py-2 px-3 rounded-lg mt-2 inline-block">
                  {study.category}
                </span>
              </div>
            </div>
          ))}
        </div>
        {/* Pagination */}
        <div className="flex justify-center mt-6 space-x-2">
          <button className="px-3 py-1 ">{"<"}</button>
          <button className="px-3 py-1 rounded-full bg-white text-black border border-black hover:bg-gray-100">
            1
          </button>
          <button className="px-3 py-1 rounded-full bg-white text-black border border-black hover:bg-gray-100">
            2
          </button>
          <button className="px-3 py-1 rounded-full bg-white text-black border border-black hover:bg-gray-100">
            3
          </button>
          <button className="px-3 py-1 ">{">"}</button>
        </div>
      </div>
      {/* Horizontal Line */}
      {/* <hr className="border-t-8 border-[#F4F4F4] my-8 mx-auto w-full mt-14" /> */}
    </div>
  );
};

export default CaseStudiesPage;
