import React, { useEffect } from "react";
import "./GameContainer.css";
import CloudSVG from "../assets/clouds.svg";
import coins from "../assets/coin.svg";
import playbtn from "../assets/play.svg";
import pausebtn from "../assets/pause.svg";
import restartbtn from "../assets/restart.svg";
import space from "../assets/space.svg";
import right from "../assets/right.svg";
import left from "../assets/left.svg";
const GameContainer = () => {
  const initalGame = () => {
    const currentScore = document.getElementById("currentScore");
    const shootingButton = document.getElementById("shootingButton");
    const bulletArea = document.getElementById("bulletArea");
    const lifeLeft = document.getElementById("lifeLeft");
    const pauseDiv = document.getElementById("pauseDiv");
    //lifeLeft
    //bulletArea

    const gameArea = document.getElementById("gameArea");
    const startButton = document.getElementById("startButton");
    const playButton = document.getElementById("playButton");
    const pauseButton = document.getElementById("pauseButton");
    const restartButton = document.getElementById("restartButton");
    const exitButton = document.getElementById("exitButton");
    exitButton.addEventListener("click", () => {
      window.location.reload();
    });

    let position = gameArea.clientWidth / 2 - 40;

    let bullets = [];
    let obstacles = [];

    let gameInterval;
    let score = 0;
    let lives = 6;
    let highScore = 0;
    const obstacleSpeed = 1; // Slow down the obstacle speed
    let obstacleIdCounter = 0;
    let gameRunning = false;
    let velocity = 0;
    let acceleration = 2;
    let maxSpeed = 12;
    let friction = 0.9;

    const initializeValue = () => {
      currentScore.innerHTML = 0;
      lifeLeft.innerHTML = lives;
      updateLivesDisplay();
    };

    const cities = [
      { name: "Performance Issues", },
      {
        name: "Security Vulnerabilities",
      },
      { name: "Cost Crep", },
      {
        name: "Resource Misallocation",
      },
      { name: "Low Storage", },
      {
        name: "Not Enough Spped",
      },
      { name: "No Savings Plan", },
      { name: "Over pricing", },
    ];

    initializeValue();

    const pressedKeys = {};
    let shootTimer;

    const keyDownEvent = (event) => {
      if (event.key === "r" || event.key === "R") {
        restartGame();
      } else if (event.key === "p" || event.key === "P") {
        pauseGame(true);
      } else if (event.key === "s" || event.key === "S") {
        startGame();
      }
      if (!gameRunning) {
        return;
      } else if (event.code === "Space") {
        event.preventDefault();
        pressedKeys["Space"] = true;
        // shootBullet();
        // console.log("shootTimer",shootTimer)
        shootBullet();
        if (!shootTimer) {
          shootTimer = setInterval(() => {
            shootBullet();
          }, 100);
        }
      }
      if (event.key === "ArrowLeft")
        velocity = Math.max(velocity - acceleration, -maxSpeed);
      if (event.key === "ArrowRight")
        velocity = Math.min(velocity + acceleration, maxSpeed);
    };
    window.addEventListener("keydown", keyDownEvent);

    function updateAircraft() {
      position += velocity;
      velocity *= friction;
      position = Math.max(
        0,
        Math.min(position, gameArea.clientWidth - shootingButton.clientWidth)
      );
      shootingButton.style.left = position + "px";
      requestAnimationFrame(updateAircraft);
    }
    updateAircraft();
    const keyUP = (event) => {
      if (event.code === "Space") {
        clearInterval(shootTimer);
        shootTimer = null;
      }
    };
    window.addEventListener("keyup", keyUP);

    function breakCloud(bullet, cloud) {
      let cloudX = parseInt(bullet.style.left);
      let cloudY = parseInt(cloud.style.top);

      for (let i = 0; i < 4; i++) {
        let piece = document.createElement("div");
        piece.classList.add("piece");
        // piece.clientWidth=cloud.clientWidth+'px';
        piece.style.left = cloudX + "px";
        piece.style.top = cloudY + Math.random() * 20 + "px";
        gameArea.appendChild(piece);
        setTimeout(() => piece.remove(), 500);
      }

      createCoin(cloudX, cloudY);
    }
    function createCoin(x, y) {
      const coin = document.createElement("div");
      coin.classList.add("coin");
      coin.style.left = x + "px";
      coin.style.top = y + "px";
      gameArea.appendChild(coin);
      setTimeout(() => coin.remove(), 500);
    }

    function shootBullet() {
      const bullet = document.createElement("div");
      bullet.classList.add("bullet");

      bullet.style.left =
        shootingButton.offsetLeft + shootingButton.clientWidth / 2 + "px";
      bullet.style.bottom = "10%";
      bulletArea.appendChild(bullet);
      bullets.push(bullet);
    }

    function createObstacle() {
      if (obstacles.length >= 1) return;
      const city = cities[obstacleIdCounter % cities.length];
      const obstacle = document.createElement("div");
      obstacle.classList.add("obstacle");
      obstacle.style.left = Math.random() * (gameArea.clientWidth - 150) + "px";
      obstacle.style.top = "0px";
      obstacle.dataset.id = obstacleIdCounter;

      const obstacleImage = document.createElement("img");
      obstacleImage.src = city.image;
      obstacleImage.style.width = "100%";
      obstacleImage.style.height = "50%";
      const obstacleText = document.createElement("div");
      obstacleText.innerText = city.name;
      //   obstacle.appendChild(obstacleImage);
      obstacle.appendChild(obstacleText);
      gameArea.appendChild(obstacle);
      obstacles.push(obstacle);
      obstacleIdCounter++;
    }

    function isColliding(bullet, obstacle) {
      const bRect = bullet.getBoundingClientRect();
      const oRect = obstacle.getBoundingClientRect();
      // console.log({bRect,oRect})
      return !(
        bRect.top > oRect.bottom ||
        bRect.bottom < oRect.top ||
        bRect.left > oRect.right ||
        bRect.right < oRect.left
      );
    }

    function isCollidingWithAircraft(obstacle) {
      const aRect = shootingButton.getBoundingClientRect();
      const oRect = obstacle.getBoundingClientRect();
      return !(
        aRect.top > oRect.bottom ||
        aRect.bottom < oRect.top ||
        aRect.left > oRect.right ||
        aRect.right < oRect.left
      );
    }

    function updateScores() {
      currentScore.innerText = score;
      if (score > highScore) {
        highScore = score;
        // highScoreDisplay.innerText = "High Score: " + highScore;
      }
    }
    function updateLivesDisplay() {
      // 🩶
      lifeLeft.innerHTML = "❤️".repeat(lives) + "🩶".repeat(6 - lives);
    }
    function removeObstacle(obstacle, index) {
      obstacle.remove();
      obstacles.splice(index, 1);
    }

    function updateGame() {
      bullets.forEach((bullet, index) => {
        const bulletBottom = parseInt(bullet.style.bottom);
        bullet.style.bottom = bulletBottom + 5 + "px";
        if (bulletBottom > gameArea.clientHeight) {
          bullet.remove();
          bullets.splice(index, 1);
        }
      });
      obstacles.forEach((obstacle, index) => {
        const obstacleTop = parseInt(obstacle.style.top);
        obstacle.style.top = obstacleTop + obstacleSpeed + "px";
        if (obstacleTop + obstacle.clientHeight > gameArea.clientHeight) {
          obstacle.remove();
          obstacles.splice(index, 1);
          loseLife();
          return;
        }
        bullets.forEach((bullet, bIndex) => {
          if (isColliding(bullet, obstacle)) {
            breakCloud(bullet, obstacle);
            bullet.remove();
            bullets.splice(bIndex, 1);
            removeObstacle(obstacle, index);
            score += 10;
            updateScores();
          }
        });
        if (isCollidingWithAircraft(obstacle)) {
          removeObstacle(obstacle, index);
          loseLife();
        }
      });
    }
    function loseLife() {
      lives--;
      // lifeLeft.innerHTML = lives;
      updateLivesDisplay();
      gameArea.classList.add("flash", "shake");
      setTimeout(() => gameArea.classList.remove("flash", "shake"), 300);
      if (lives <= 0) {
        alert("Game Over");
        pauseGame();
      }
    }
    function startGame() {
      if (gameRunning) return;
      gameArea.classList.remove("blur-sm");
      pauseDiv.classList.remove("show_pause");
      gameRunning = true;
      score = 0;
      lives = 6;
      updateScores();
      updateLivesDisplay();
      gameInterval = setInterval(() => {
        updateGame();
        if (Math.random() < 0.02) {
          createObstacle();
        }
      }, 1000 / 60);
    }

    function pauseGame(isFromPause) {
      clearInterval(gameInterval);
      clearInterval(shootTimer);
      gameRunning = false;
      if (isFromPause) {
        gameArea.classList.add("blur-sm");

        // const pauseText = document.createElement("div");
        pauseDiv.classList.add("show_pause");
      }

      // bullet.appendChild(pauseGame);
      // gameArea.appendChild(pa);
    }

    function restartGame() {
      pauseGame();
      gameArea.classList.remove("blur-sm");
      pauseDiv.classList.remove("show_pause");
      obstacles.forEach((obstacle) => obstacle.remove());
      obstacles = [];
      bullets.forEach((bullet) => bullet.remove());
      bullets = [];
      score = 0;
      lives = 6;
      updateScores();
      updateLivesDisplay();
      startGame();
    }

    startButton.addEventListener("click", startGame);
    playButton.addEventListener("click", startGame);
    pauseButton.addEventListener("click", pauseGame);
    restartButton.addEventListener("click", restartGame);
    exitButton.addEventListener("click", () => {
      document.querySelector(".cstmbgmain").style.display = "none";
    });
    

    pauseGame();
    return () => {
      window.removeEventListener("keydown", keyDownEvent);
      window.removeEventListener("keydown", keyDownEvent);

      startButton.removeEventListener("click", startGame);
      playButton.removeEventListener("click", startGame);
      pauseButton.removeEventListener("click", pauseGame);
      restartButton.removeEventListener("click", restartGame);
    };
  };

  useEffect(() => {
    const cleanup = initalGame();
    return cleanup;
  }, []);

  return (
    <div className="cstmbgmain">

      <div
        id="gameControls"
        className="!mt-10"
      >
        <button id="startButton" className="spcbtnmn">Start the Game</button>
        <button id="playButton" className="spcbtnmn"> <img className="btninst" src={playbtn} alt="" /></button>
        <button id="pauseButton" className="spcbtnmn"><img src={pausebtn} className="btninst" alt="" /></button>
        <button id="restartButton"><img src={restartbtn} className="btninst" alt="" /></button>
        <button id="exitButton" className="spcbtnmn">Exit Game</button>
      </div>
      <img
        src={CloudSVG}
        alt=""
        className="cldspamn animationmn"
      />
      <img
        src={CloudSVG}
        alt=""
        className="cldspamn2 animationmnn"
      />
      <img
        src={CloudSVG}
        alt=""
        className="cldspamn3 animationmnn"
      />
      <img
        src={CloudSVG}
        alt=""
        className="cldspamn4 animationmn"
      />
      <div className="gameboxcontainer">
        <div className="flex flex-row justify-between clrwhiteup">
          <div className="scorealign">
            <img className="coinscre" src={coins} alt="" /> Savings : <span id="currentScore"></span>
          </div>
          <div className="lifesmn">
            <span id="lifeLeft"></span>
          </div>
        </div>

        <div className="relative h-full">
          <div
            id="gameArea"
            className="overflow-hidden relative rounded-md h-full mt-2 clrwhite"
          >
            <div
              id="bulletArea"
              className="absolute top-0 bottom-0 left-0 right-0"
            ></div>
            <div className="absolute bottom-0">
              <button
                id="shootingButton"
                className="absolute bottom-0"
              >
                {/* <!-- <img src="/logo.svg" className="aircraftlg" alt=""> --> */}
              </button>
            </div>
          </div>
          <div
            id="pauseDiv"
            className="absolute top-0 bottom-0 left-0 right-0 flex-col justify-center hide"
          >
            <div className="drop-shadow-2xl text-center psdgm">Game Paused</div>
          </div>
        </div>

        {/* <!-- BUTTON FOR HELP --> */}
        <div
          className="d-flex"
          style={{
            position: "relative",
            zIndex: 9,
            //   "position: relative; z-index: 9;"
          }}
        >
          <div>
            {/* <div className="clrslm">Press S to start</div>
         <div className="clrslm">Press R to Restart</div> */}
          </div>

          <div className="lftrgt">
            <div className="spcnmg">
              <img className="btnbtminst" src={space} alt="" />
              Shoot
            </div>
            <span className="leftbtnbtm spcnmglr">
              <img className="btnbtminst" src={left} alt="" />
              Move Left
            </span>
            <span className="leftbtnbtm">
              <img className="btnbtminst" src={right} alt="" />
              Move right
            </span>

          </div>

        </div>
      </div>
    </div>
  );
};

export default GameContainer;

