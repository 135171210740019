import React, { useState } from 'react';
import logo from "../assets/CE.svg";
import downdrop from "../assets/downdrop.webp";
import Vector from "../assets/Vector.webp";
import savings from "../assets/savings.webp";
import cloud from "../assets/cloudd.webp";
import warehouse from "../assets/warehouse.webp";
import faq from "../assets/faq.webp";
import blg from "../assets/blg.webp";
import casestu from "../assets/casestu.webp";
import document from "../assets/cloudd.webp";
import Twitter from '../assets/twitter.webp';
// import Facebook from '../assets/facebook.webp';
import Internet from '../assets/linkedin 1.webp';
// import Insta from '../assets/insta.webp';
import Youtube from '../assets/youtube.webp';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [dropdownState, setDropdownState] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const navigate = useNavigate();

  const navbarConfig = {
    logo,
    brandName: "",
    menuItems: [
      { name: "About", link: "/aboutus" },
      {
        name: "Platform",
        dropdown: [
          { name: "Cloud 360", link: "/platform/Cloud360", path: cloud, subname: 'Full cloud intelligence', flag: 2 },
          { name: "Savings Warehouse", link: "/platform/warehouse", path: warehouse, subname: 'Direct AWS savings', flag: 1 },
          { name: "Savings CoPilot", link: "#", path: savings, subname: 'Savings Copilot - coming soon ', flag: 3 },
        ],
      },
      { name: "Services", link: "/cloudmanagedservices" },
      { name: "Pricing", link: "/pricing" },
      {
        name: "Resources",
        dropdown: [
          { name: "FAQs", path: faq, link: "/Faq", subname: 'Clarity in every query' },
          { name: "Blogs", path: blg, link: "/Blogs", subname: 'Learn, adapt, evolve' },
          { name: "Case Studies", path: casestu, link: "/platform/case-study", subname: ' Proof of savings' },
          { name: "Documentation", path: document, link: "", subname: 'Guide for every step' }
        ],
      },
      { name: "Testimonials", link: "/Testimonials" },
    ],
    buttons: [
      {
        label: "Get in touch",
        action: "/contactus",
        style: "bg-[#02AAE2] text-white font-bold",
      },
      {
        label: "Sign in",
        action: "https://app.cloudevolve.com/auth/sign-in",
        style: "border border-gray-400 text-black font-bold",
      },
    ],
  };

  const renderDropdown = (dropdown, isOpen) => {
    return (
      <div className="absolute left-0 w-full" style={{ top: '100%', marginTop: '0.5rem' }}>
        {/* Invisible bridge to prevent dropdown from closing */}
        <div
          className={`absolute left-0 w-full h-4 -top-4 ${isOpen ? "block" : "hidden"}`}
          style={{ background: 'transparent' }}
        />
        <ul
          className={`absolute mt-0 border border-black bg-[#FAFAFA] shadow-lg rounded-xl flex justify-between flex-row font-semibold z-50 transition-all duration-300 ease-in-out ${isOpen ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-2 pointer-events-none"
            }`}
          style={{
            width: dropdown?.[0]?.flag === 2 ? "870px" : "1160px",
            padding: "15px",
            marginLeft: dropdown?.[0]?.flag === 2 ? "-170px" : "-675px",
          }}
        >
          {dropdown?.map((item, idx) => (
            <li
              key={idx}
              onClick={() => {
                if (item.name !== "Savings CoPilot" && item.name !== "Case Studies") {
                  if (item.name === "Documentation") {
                    window.open("https://docs.cloudevolve.com/docs/intro", "_blank");
                  } else {
                    navigate(item.link);
                  }
                  setDropdownState(null);
                }
              }}
              onMouseEnter={() => setHoveredItem(item.name)}
              onMouseLeave={() => setHoveredItem(null)}
              className={`flex items-center px-4 py-2 transition-all duration-200 ease-in-out rounded-lg ${hoveredItem === item.name ? "bg-gray-100 scale-105" : ""
                } ${item.name === "Savings CoPilot" || item.name === "Case Studies"
                  ? "cursor-not-allowed opacity-70"
                  : "hover:bg-gray-100 cursor-pointer"
                } space-x-4`}
            >
              {item.path && (
                <div
                  className={`hidden lg:block transition-transform duration-200 ${hoveredItem === item.name ? "scale-110" : ""
                    }`}
                  style={{
                    borderRadius: "8px",
                    border: "0.15px solid #222",
                    padding: "6px",
                    background: "#FFF",
                  }}
                >
                  <img
                    src={item.path}
                    loading="lazy" effect="blur"
                    alt={item.name || "Icon"}
                    className="h-6 w-6"
                  />
                </div>
              )}
              <div className="flex flex-col text-left">
                <span className={`text-sm transition-all duration-200 ${hoveredItem === item.name ? "font-semibold" : ""
                  }`}>{item.name}</span>
                {item.subname && (
                  <p className={`text-xs mt-1 hidden lg:block font-normal transition-all duration-200 ${hoveredItem === item.name ? "" : "text-gray-600"
                    }`}>
                    {item.subname}
                  </p>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderDropdown1 = (dropdown, isOpen) => {
    return (
      <ul
        className={`left-0 rounded-md flex flex-col items-center jakarta px-3 text-sm z-50 ${isOpen ? "block" : "hidden"}`}
        style={{
          width: "100%",
          textAlign: "center",
          top: "100%",
        }}
      >
        {dropdown.map((item, idx) => (
          <li
            key={idx}
            role="button"
            onClick={() => {
              // Prevent navigation for disabled items (Savings CoPilot & Case Studies)
              if (item.name !== "Savings CoPilot" && item.name !== "Case Studies") {
                if (item.name === "Documentation") {
                  window.open("https://docs.cloudevolve.com/docs/intro", "_blank");
                } else {
                  navigate(item.link);
                }
                setDropdownState(null);
              }
            }}
            className={`flex flex-col items-center space-y-2 px-2 py-2 ${item.name === "Savings CoPilot" || item.name === "Case Studies"
              ? "cursor-not-allowed opacity-70"
              : "hover:bg-gray-700 cursor-pointer"
              } w-full`}
          >
            <span className="flex flex-row items-center">
              <button
                type="button"
                className="text-center font-medium jakarta focus:outline-none"
                disabled={item.name === "Savings CoPilot" || item.name === "Case Studies"}
                onClick={() => setIsMenuOpen(false)}
              >
                {item.name}
              </button>
              {item.flag === 3 && (
                <span className="ml-2 font-bold text-red-500">Coming Soon</span>
              )}
            </span>
          </li>
        ))}
      </ul>
    );
  };


  const toggleDropdown = (name) => {
    setDropdownState((prev) => (prev === name ? null : name));
  };

  const handleMenuClick = (name) => {
    setActiveMenu(name);
  };

  return (
    <nav className="bg-white px-6 py-2 flex items-center fixed top-0 left-0 w-full z-[100]">
      {/* Logo and Brand (Always Visible) */}
      <div
        className="flex flex-1 items-center cursor-pointer transition-transform duration-200 hover:scale-105"
        onClick={() => navigate('/home')}
      >
        <img src={navbarConfig.logo} loading="lazy" alt="Logo" className="h-5 w-auto" />
        <span className="ml-2 text-2xl rubik">{navbarConfig.brandName}</span>
      </div>

      <div className="hidden lg:flex items-center flex-1 justify-center">
        <ul className="flex rubik items-center space-x-6 lg:space-x-6 xl:space-x-12 2xl:space-x-14 font-medium text-base text-[#222222]">
          {navbarConfig.menuItems.map((item, idx) => (
            <li
              key={idx}
              className="relative group py-4"
              onMouseEnter={() => item.dropdown && toggleDropdown(item.name.toLowerCase())}
              onMouseLeave={() => item.dropdown && toggleDropdown(null)}
            >
              {item.dropdown ? (
                <div className="relative">
                  <span className={`flex items-center transition-all duration-200 cursor-pointer ${activeMenu === item.name ? "underline font-semibold" : ""}`}>
                    {item.name}
                    <img
                      src={downdrop}
                      loading="lazy"
                      alt="Dropdown Icon"
                      className={`ml-1 mt-1 w-4 h-4 transform transition-transform duration-300 ${dropdownState === item.name.toLowerCase() ? "rotate-180" : ""}`}
                    />
                  </span>
                  {renderDropdown(item.dropdown, dropdownState === item.name.toLowerCase())}
                </div>
              ) : (
                <a
                  href={item.link}
                  onClick={() => handleMenuClick(item.name)}
                  className={`transition-all duration-200 hover:underline hover:underline-offset-4 hover:decoration-2 ${activeMenu === item.name ? "underline underline-offset-4 decoration-2 font-semibold" : ""}`}
                >
                  {item.name}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Buttons - Hidden on Mobile & Tablet, Visible on lg (992px) and above */}
      <div className="hidden lg:flex flex-1 justify-end space-x-4">
        {navbarConfig.buttons.map((button, idx) => (
          <button
            key={idx}
            className={`${button.style} px-4 py-2 jakarta rounded-md text-sm transition-all duration-200 hover:scale-105 hover:shadow-md`}
            style={{ border: "1px solid black" }}
            onClick={() => window.location.href = button.action}
          >
            {button.label}
          </button>
        ))}
      </div>





      {/* Small Screen Dropdown Menu */}
      {/* Small Screen Menu Toggle */}
      {!isMenuOpen ? (
        <button onClick={() => setIsMenuOpen(true)} className="lg:hidden">
          <img src={Vector} alt="Menu Icon" className="h-4 w-4" />
        </button>
      ) : (
        <button
          type="button"
          className="lg:hidden close jakarta text-gray-700"
          aria-label="Close"
          onClick={() => setIsMenuOpen(false)}
        >
          <span aria-hidden="true" className="text-2xl">
            ×
          </span>
        </button>
      )}

      {/* Small Screen Dropdown Menu */}
      <div
        className={`${isMenuOpen ? "block" : "hidden"
          } lg:hidden absolute top-10 left-0 w-full bg-white shadow-lg z-[99]`}
      >
        <ul className="flex flex-col space-y-4 px-6 py-4 bg-white text-gray-800 items-center jakarta">
          {navbarConfig.menuItems.map((item, idx) => (
            <li key={idx} className="relative hover:text-gray-900 cursor-pointer w-full text-center items-center">
              {item.dropdown ? (
                <>
                  <span
                    onClick={() => {
                      toggleDropdown(item.name.toLowerCase());
                      handleMenuClick(item.name);
                    }}
                    className={`flex items-center ml-[14px] justify-center ${activeMenu === item.name ? "underline font-semibold" : ""
                      }`}
                  >
                    {item.name}
                    <img
                      src={downdrop}
                      loading="lazy" effect="blur"
                      alt="Dropdown Icon"
                      className={`ml-1 mt-1 w-4 h-4 transform transition-transform duration-300 ${dropdownState === item.name.toLowerCase() ? "rotate-180" : ""
                        }`}
                    />
                  </span>
                  {renderDropdown1(
                    item.dropdown,
                    dropdownState === item.name.toLowerCase()
                  )}
                </>
              ) : (
                <a
                  href={item.link}
                  onClick={() => handleMenuClick(item.name)}
                  className={`${activeMenu === item.name ? "underline font-semibold" : ""
                    }`}
                >
                  {item.name}
                </a>
              )}
            </li>
          ))}

          {/* Small Screen Buttons */}
          <div className="flex flex-col items-center justify-center space-y-2 mt-4 w-full">
            <button
              className="bg-[#02AAE2] text-white jakarta px-4 py-2 rounded-md text-sm font-bold  w-full text-center border border-black"
              onClick={() => window.location.href = "/contactus"}
            >
              Get in touch
            </button>
            <button
              className="border bg-white jakarta border-gray-700 text-black font-bold px-4 py-2 rounded-md text-sm w-full text-center border-black"
              onClick={() => window.location.href = "https://app.cloudevolve.com/auth/sign-in"}
            >
              Sign in
            </button>
          </div>

          <p className="text-sm jakarta lg:text-base mt-4 text-gray-500 lg:hidden md:hidden block">
            © 2025 Cloudevolve.<br />
            All rights reserved.
          </p>
          <div className="flex mt-5 space-x-4 md:hidden lg:hidden">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <img src={Twitter} alt="Twitter" loading="lazy" effect="blur" className="w-3 h-3 lg:w-5 lg:h-5" />
            </a>
            <a href="https://www.linkedin.com/company/cloudevolve-com/" target="_blank" rel="noopener noreferrer">
              <img src={Internet} alt="Website" loading="lazy" effect="blur" className="w-3 h-3 lg:w-5 lg:h-5" />
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <img src={Youtube} alt="YouTube" loading="lazy" effect="blur" className="w-3 h-3 lg:w-5 lg:h-5" />
            </a>
          </div>
        </ul>
      </div>

    </nav>
  );
};

export default Navbar;
