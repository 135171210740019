import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";





const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (

        <div className=" px-6 pt-6 mt-20 flex flex-col justify-evenly mx-auto max-w-[75rem]">
            <Helmet prioritizeSeoTags>
                <title>PrivacyPolicy | Cloudevolve | From migration to monitoring, Cloudevolve cloud-managed services ensure your AWS runs smoothly, securely, and cost-effectively.</title>
                <link
                    rel="Important"
                    href="https://cloudevolve.com/PrivacyPolicy"
                />
                <meta
                    name="description"
                    content=" 
            
            From migration to monitoring, Cloudevolve’s cloud-managed services ensure your AWS runs smoothly, securely, and cost-effectively."
                />
                <meta
                    name="keywords"
                    content="Cloudevolve, cloud cost savings, cloud solutions, innovation, AWS cost management"
                />
                <meta name="author" content="Cloudevolve" />
            </Helmet>
            <h1 className="text-4xl font-bold rubik">
                Cloudevolve Privacy Policy and Terms of Usage
            </h1>
            <p className="mt-2 font-jakarta">
                Before you explore our site, take a moment to understand how we handle your data and your rights.
            </p>
            <h2 className="mt-6 text-xl font-bold rubik">
                Who We Are & What This Means for You
            </h2>
            <p className="mt-2 font-jakarta">
                This website (link) is created, owned, and operated by Cloudevolve
                (“Company” or “we”). This Privacy Policy applies to all our websites
                (“Sites”) and outlines how we collect and use your information. We are
                committed to protecting your privacy and adhere to Indian privacy laws.
            </p>
            <p className="mt-2 font-jakarta">
                There may be links from our Sites to other web sites; note that this
                Privacy Policy applies only to our Sites and not to web sites of other
                companies/organisations to which our Sites may be linked. You must
                check on any linked sites for the privacy policy of that site and/or
                make any necessary inquiries in respect of that privacy policy of
                the linked site. These links to third party websites are provided
                as a convenience and are for informational purposes only. The
                Company does not endorse, and is not responsible for, these linked
                websites. Although you are not required to register to access our
                Sites, you may be asked to provide us with personal information
                when you visit certain sections of our Sites. Your use of our
                Sites signifies your acknowledgement and consent to our Privacy Policy.
            </p>
            <p className="mt-2 font-jakarta">
                If you do not agree to this Privacy Policy, please do not continue to use
                our Sites. Your continued use of the Sites signifies your acceptance of
                these terms and any changes in effect at the time of use.
            </p>
            <h3 className="mt-6 text-xl font-bold rubik">
                Information we collect
            </h3>
            <p className="mt-2 font-jakarta">
                We collect personal information that identifies you as an individual—like your
                name, email, phone number, and address—when you voluntarily share it via contact
                forms, feedback, surveys, career inquiries, or other submissions.
            </p>
            <p className="mt-2 font-jakarta">
                Additionally, we collect information related to your access to the Service that does
                not directly identify you, such as your organization name, login credentials to the
                Service and related services, and any feedback you provide regarding the Service.
                We also gather data about your usage of other services, including utilization
                statistics for Amazon Web Services servers (your “Server Usage”) and the amount
                you spend to access such services. This information helps us improve our platform,
                understand user preferences, and enhance your overall experience.
            </p>

            <h3 className="mt-6 text-xl font-bold rubik">
                Use of Information
            </h3>
            <p className="mt-2 font-jakarta">
                Please be advised that any information gathered on our Sites may be used in the aggregate
                for research and development relating to our Sites and/or for future site development and,
                if you ask us to, to send you promotional materials. In particular, we may use information
                gathered about you for the following purposes: to monitor interest in our range of services
                and to assist us to tailor the content of our Sites to your needs by collecting information
                about your preferences through tracking of patterns page views on our Sites; to create a
                profile relating to you in order to show you the content that might be of interest to you
                and to display the content according to your preferences; and, in circumstances where you
                have indicated that you wish to receive additional information, to send you information
                about us and promotional material about our products .
            </p>

            <h3 className="mt-6 text-xl font-bold rubik">
                Information and Promotion
            </h3>
            <p className="mt-2 font-jakarta">
                Information submitted at the time of registration or submission by online visitor may be used
                with permission for marketing and promotional purposes by the Company and no other third parties
                provided notice of this fact is made available online. The Company uses reasonable efforts to
                maintain visitors’ information in a secure environment. If you have submitted personal information
                and want to change it or opt-out, please contact us. If a visitor objects to such use for any reason,
                he/she may prevent that use, either by email request or by modifying the registration information provided.
            </p>

            <h3 className="mt-6 text-xl font-bold rubik">
                Disclosure of Information
            </h3>
            <p className="mt-2 font-jakarta">
                You may use the website, and the information, writings, images, or other content that you see,
                hear or otherwise experience on the website solely for non-commercial, personal purposes or to
                know about Company offerings and services. The Company will not disclose personal information
                that you provide on its Sites to any third parties other than to a Company agent except: i)
                in accordance with the terms of this Privacy Policy, or ii) to comply with legal requirements
                such as a law, regulation, warrant, or court order, and/or iii) if you are reporting an adverse
                event/side effect, in which case the Company may be required to disclose such information to
                bodies such as, but not limited to, Indian and/or international regulatory authorities.
                Please note that any of these disclosures may involve the storage or processing of personal
                information outside of India and may therefore be subject to different privacy laws than
                those applicable in India, including laws that require the disclosure of personal information
                to governmental authorities under circumstances that are different than those that apply in India.
            </p>


            <h3 className="mt-6 text-xl font-bold rubik">
                Protection of Information
            </h3>
            <p className="mt-2 font-jakarta">
                We have put in place physical, electronic, and managerial processes to safeguard and help prevent
                unauthorized access, maintain data security, and correctly use the information we collect online.
                The Company applies security safeguards appropriate to the sensitivity of the information, such
                as retaining information in secure facilities and making personal information accessible only to
                authorized employees on a need-to-know basis.
            </p>

            <h3 className="mt-6 text-xl font-bold rubik">
                Cookies
            </h3>
            <p className="mt-2 font-jakarta">
                The Company may use standard technology called “cookies” on its Sites. Cookies are small data
                files that are downloaded onto your computer when you visit a particular web site. You can disable
                cookies by turning them off in your browser; however, some areas of the Sites may not function
                properly if you do so.
            </p>

            <h3 className="mt-6 text-xl font-bold rubik">
                Storage of Information
            </h3>
            <p className="mt-2 font-jakarta">
                Personal information you share with us is securely stored with a limited set of trusted
                third-party application providers. All these providers have privacy policy agreements
                that are in alignment with ours, however, many of them are outside of India and have
                regional variations as required by law.
            </p>

            <h3 className="mt-6 text-xl font-bold rubik">
                Policy Change
            </h3>
            <p className="mt-2 font-jakarta">
                If we alter our Privacy Policy, any changes will be posted on this page of our Site so that you
                are always informed of the information we collect about you, how we use it and the circumstances
                under which we may disclose it.
            </p>


        </div>



    );
};

export default PrivacyPolicy;
