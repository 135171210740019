import React, { useEffect, useRef, useState } from "react";
import rightsidearrow from "../assets/rightarrow.webp";
// import firstCardimg from '../assets/warehouse/firstCardimg.webp';
// import arrowblc from "../assets/warehouse/arrowblc.webp";

import nosaving from "../assets/Frame13.webp";
import upto from "../assets/Frame15.webp";
import cloudany from "../assets/Frame14.webp";
import cloudev from "../assets/Frame16.webp";
import homeCurve from "../assets/Vector 625.webp";
// import abc from '../assets/homeimg/abc.webp';
// import bbc from '../assets/homeimg/bbc.webp';
// import black from '../assets/homeimg/black.webp';
// import gateway from '../assets/homeimg/gateway.webp';
// import jack from '../assets/homeimg/jack.webp';
// import sugar from '../assets/homeimg/sugar.webp';
// import wwf from '../assets/homeimg/wwf.webp';
// import xbox from '../assets/homeimg/xbox.webp';
import sea from "../assets/cloud bills.webp";
import sec from "../assets/aws price.webp";
import third from "../assets/cloud cost.webp";
import uparrowicon from "../assets/uparrowicon.webp";
//import cld from '../assets/homeimg/cld.webp';
// import swarehouse from '../assets/homeimg/swarehouse.webp';
// import savingCo from '../assets/homeimg/savingCo.webp';
// import secure from '../assets/secure.webp';
import blg from "../assets/blgg.webp";
import test from "../assets/test.webp";
import Test1 from "../assets/test1.webp";
// import price from '../assets/homeimg/price.webp';
// import prc2 from '../assets/homeimg/prc2.webp';
// import huge from '../assets/homeimg/huge.webp';
import checkCircleIcon from "../assets/check-badge-01_1.gif";
import Vector624 from "../assets/Vector 624.webp";
import plus from "../assets/plus.webp";
import xmark from "../assets/xmark.webp";
import { useNavigate } from "react-router-dom";

// import Icon1 from "../assets/Icon1.webp";
import Group1 from "../assets/Group1.webp";
// import Hand from "../assets/hand.webp";
import Cloud from "../assets/cloud.webp";
import Group2 from "../assets/group2.webp";
import data from "../assets/data.json";
import Lottie from "lottie-react";
// import Coming from "../assets/coming.mp4";
import Secure from "../assets/secure2.mp4";
import Hero from "../assets/home.mp4";
import HeroMobile from "../assets/mobile.gif";
import Savings from "../assets/warehouse gif.gif";
import Hand from "../assets/get.gif";

import Novagems from "../assets/Novagems-logo.webp";
import Lenskart from "../assets/Lenskart-logo.webp";
import Jubna from "../assets/jubna-logo.webp";
import Fitelo from "../assets/Fitelo-Logo.webp";
import Witzeal from "../assets/witzeal-logo.webp";
import Steller from "../assets/stellarinfo-logo.webp";
import People from "../assets/Peoplestrong-logo.webp";
import Outleap from "../assets/Outleap GeeBee-logo.webp";

import Frame5 from "../assets/Frame55.svg";
import Frame6 from "../assets/Frame66.svg";
import Frame7 from "../assets/Frame7.webp";
import Frame8 from "../assets/Frame8.webp";
import Frame9 from "../assets/Frame19.svg";
import Frame10 from "../assets/Frame10.webp";
import Frame11 from "../assets/Frame11.webp";
import Frame12 from "../assets/Frame122.svg";
import Frame13 from "../assets/Frame133.svg";
import Frame14 from "../assets/Frame144.svg";
import Frame15 from "../assets/Frame155.svg";
import Frame16 from "../assets/Frame166.svg";
import Line from "../assets/line.svg";

import { Helmet } from "react-helmet-async";
import ResponsiveHero from "./ResponsiveHero";
// import Gameimg from "../assets/mobile.webp";
// import Gamedesk from "../assets/gamelap.png";
import Play from "../assets/play.svg";
import Game from "../Game/GameContainer";
import Gamemobile from "../assets/game.gif";
import Gamedesktop from "../assets/game desktop.mp4";
import Navbar from "../Common/Navbar";
import copilot from "../assets/copilot.gif";
const Home = () => {
  const scrollRef = useRef(null);
  const [openIndex, setOpenIndex] = useState(null);
  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const faqs = [
    {
      question: "Are there any commitments required?",
      answer:
        "No, we believe in flexibility! You can enjoy savings without any long-term commitments.",
      //tags: ["Cloud cost", "AWS"],
    },
    {
      question: "Is there any fee to join?",
      answer: "No, there is no fee to join.",
      //tags: ["GCP", "Kubernetes"],
    },
    {
      question: "What makes Cloudevolve different from other platforms?",
      answer:
        "Cloudevolve offers competitive pricing and seamless integration.",
      //tags: ["Snowflake", "Cloud cost"],
    },
    {
      question: "How often does pricing adjust to usage changes?",
      answer: "Pricing is adjusted based on your usage every month.",
      //tags: ["AWS", "Kubernetes"],
    },
    {
      question: "Can I access detailed reports on my spending?",
      answer:
        "Yes, you can access detailed spending reports through the dashboard.",
      //tags: ["GCP", "Snowflake"],
    },
    {
      question: "What happens if my cloud usage changes?",
      answer:
        "Your pricing adjusts automatically to reflect your current usage.",
      //tags: ["Cloud cost"],
    },
    {
      question: "Are there any hidden fees?",
      answer: "No, we maintain complete transparency with our pricing.",
      tags: ["AWS", "Snowflake"],
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        const container = scrollRef.current;

        // Scroll to the next item
        container.scrollBy({
          left: container.offsetWidth,
          behavior: "smooth",
        });

        // If it's the end, reset to the start
        if (
          container &&
          container.scrollLeft + container.offsetWidth >= container.scrollWidth
        ) {
          setTimeout(() => {
            if (container) {
              container.scrollTo({ left: 0, behavior: "smooth" });
            }
          }, 3000); // Add delay before resetting
        }
      }
    }, 3000); // Scroll every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();
  <div onClick={() => navigate("/bookyour")}></div>;

  const cardData = [
    {
      title: "No Savings Plan",
      description: "Save on cloud costs,\nno commitments needed.",
      icon: nosaving,
      alt: "Discount Icon",
    },
    {
      title: "Up to 60% Discounts",
      description:
        "Slash your cloud costs by up to 60%,\nno long-term deals required.",
      icon: upto,
      alt: "No Commitment Icon",
    },
    {
      title: "Cloud Analytics",
      description:
        "Stay a step ahead with real-time insights\ntailored to your cloud’s performance.",
      icon: cloudany,
      alt: "Cost Transparency Icon",
    },
    {
      title: "Secured",
      description: "Fortify your cloud with security that’s\nalways on guard.",
      icon: cloudev,
      alt: "Personalized Savings Icon",
    },
    {
      title: "Prowler",
      description:
        "Proactively monitor and strengthens cloud security with real-time risk assessments.",
      icon: Frame6,
      alt: "Prowler Icon",
    },
    {
      title: "Cost Fixer",
      description:
        "Detect inefficiencies and optimize cloud spending effortlessly.",
      icon: Frame7,
      alt: "Cost ",
    },
  ];

  const blogs = [
    {
      date: "23/04/24",
      title: "How Cloudevolve Slashes 60% Off Your Cloud Bills ",
      tags: ["#CloudSavings", "#AWSDiscounts", "#CostOptimization"],
      image: sea, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "AWS Price Hikes in 2024? How to Prepare and Protect Your Budget ",
      tags: ["#AWSPriceHike", "#CloudBudgeting", "#SaveOnAWS"],
      image: sec, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title:
        "Cloud Cost Optimization in 2024: Trends, Insights, and How to Stay Ahead",
      tags: ["#AIDrivenSavings", "#FinOpsBestPractices", "#OptimizeYourCloud"],
      image: third, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "How Cloudevolve Slashes 60% Off Your Cloud Bills ",
      tags: ["#CloudSavings", "#AWSDiscounts", "#CostOptimization"],
      image: sea, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "AWS Price Hikes in 2024? How to Prepare and Protect Your Budget ",
      tags: ["#AWSPriceHike", "#CloudBudgeting", "#SaveOnAWS"],
      image: sec, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title:
        "Cloud Cost Optimization in 2024: Trends, Insights, and How to Stay Ahead",
      tags: ["#AIDrivenSavings", "#FinOpsBestPractices", "#OptimizeYourCloud"],
      image: third, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "How Cloudevolve Slashes 60% Off Your Cloud Bills ",
      tags: ["#CloudSavings", "#AWSDiscounts", "#CostOptimization"],
      image: sea, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "AWS Price Hikes in 2024? How to Prepare and Protect Your Budget ",
      tags: ["#AWSPriceHike", "#CloudBudgeting", "#SaveOnAWS"],
      image: sec, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title:
        "Cloud Cost Optimization in 2024: Trends, Insights, and How to Stay Ahead",
      tags: ["#AIDrivenSavings", "#FinOpsBestPractices", "#OptimizeYourCloud"],
      image: third, // Replace with actual image path
    },
  ];
  const blogs12 = [
    {
      content:
        "Our AWS bills were skyrocketing until we discovered Cloudevolve. Their platform cut our costs significantly without any upfront commitments. Truly a game-changer for our business!",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content:
        "Cloudevolve makes cloud savings effortless. We saved over 50% on our cloud expenses, and their insights helped us optimize resource utilization like never before.",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content:
        "What sets Cloudevolve apart is their risk-free approach to savings. We saw tangible benefits within weeks!",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content:
        "Their Cloud 360 Intelligence Platform provided unparalleled visibility into our AWS spending. The detailed analytics helped us stay on top of every dollar spent.",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content:
        "From cost optimization to real-time insights, Cloudevolve has become an indispensable partner in our cloud journey. Highly recommended for any AWS user!",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content:
        "Joining Cloudevolve was the best decision for our cloud management strategy. It's like having a personal cloud savings expert on your team.",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content:
        "Our AWS bills were skyrocketing until we discovered Cloudevolve. Their platform cut our costs significantly without any upfront commitments. Truly a game-changer for our business!",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content:
        "Cloudevolve makes cloud savings effortless. We saved over 50% on our cloud expenses, and their insights helped us optimize resource utilization like never before.",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content:
        "What sets Cloudevolve apart is their risk-free approach to savings. We saw tangible benefits within weeks!",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content:
        "Their Cloud 360 Intelligence Platform provided unparalleled visibility into our AWS spending. The detailed analytics helped us stay on top of every dollar spent.",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content:
        "From cost optimization to real-time insights, Cloudevolve has become an indispensable partner in our cloud journey. Highly recommended for any AWS user!",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content:
        "Joining Cloudevolve was the best decision for our cloud management strategy. It's like having a personal cloud savings expert on your team.",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
  ];
  const problemCards = [
    {
      icon: Frame5,
      title: "Do I need to buy Savings Plan?",
      solution: "No, we offer savings without commitments.",
      color: "#FFF8CF",
    },
    {
      icon: Frame7,
      title: "Reduce my Cloud cost up to 60%?",
      solution: "Easy! Instant discounts, no commitments.",
      color: "#E2E5FF",
    },
    {
      icon: Frame10,
      title: "High Data Transfer Costs?",
      solution: "Reduce expenses with Savings Warehouse.",
      color: "#FFDAE8",
    },
    {
      icon: Frame6,
      title: "Unpredictable AWS Bills?",
      solution: "Track and optimize costs with Spend Insight.",
      color: "#D7FFEE",
    },
    {
      icon: Frame11,
      title: "Can’t Set a Cloud Budget?",
      solution: "Monitor and control spend using Spend Tracker.",
      color: "#FCE5E3",
    },
    {
      icon: Frame8,
      title: "Worried About EC2 Right-Sizing?",
      solution: "Automate scaling with Cloudevolve.",
      color: "#E2E5FF",
    },
    {
      icon: Frame9,
      title: "Paying for Idle Resources?",
      solution: "Tag Orbit identifies and eliminates waste.",
      color: "#D7FAFE",
    },
    {
      icon: Frame13,
      title: "Skyrocketing S3 Costs?",
      solution: "Optimize storage tiers with Savings Copilot.",
      color: "#FAEADE",
    },
    {
      icon: Frame14,
      title: "Overprovisioning Resources?",
      solution: "Optimize workloads with Rightsizing Resources.",
      color: "#FCE3FB",
    },
    {
      icon: Frame15,
      title: "Surprised by Hidden Charges?",
      solution: "Gain visibility with Cloud 360’s cost insights.",
      color: "#F6FFB8",
    },
    {
      icon: Frame12,
      title: "Is Your Workload Secure?",
      solution: "Ensure compliance with Cloud Patrol’s checks.",
      color: "#FFECDD",
    },
    {
      icon: Frame16,
      title: "Inefficient Workload Performance?",
      solution: "Fine-tune operations with Cloudevolve.",
      color: "#D7FAFE",
    },
    {
      icon: Frame5,
      title: "Confused About Savings Plans?",
      solution: "Skip commitments with Savings Warehouse.",
      color: "#FFF8CF",
    },
    {
      icon: Frame6,
      title: "Can’t Manage Multi-Tagging?",
      solution: "Streamline with Tag Orbit’s automation.",
      color: "#D7FFEE",
    },
    {
      icon: Frame7,
      title: "Is Your VPC Setup Cost-Effective?",
      solution: "Get optimized network recommendations.",
      color: "#E2E5FF",
    },
  ];

  // const duplicatedProblemCards = Array.from({ length: 1 }, () => problemCards).flat();

  const firstHalf = problemCards.slice(0, Math.ceil(problemCards.length / 2));
  const secondHalf = problemCards.slice(Math.ceil(problemCards.length / 2));

  const scrollContainerRef = useRef(null);
  const reverseScrollContainerRef = useRef(null);
  const interval1 = useRef(null);
  const interval2 = useRef(null);
  const scrollSpeed = 1;

  const startScrollingRow1 = () => {
    if (!scrollContainerRef.current) return;

    interval1.current = setInterval(() => {
      const container = scrollContainerRef.current;
      if (!container) return;

      if (
        container.scrollLeft >=
        container.scrollWidth - container.clientWidth
      ) {
        container.scrollLeft = 0;
      } else {
        container.scrollLeft += scrollSpeed;
      }
    }, 30);
  };

  const startScrollingRow2 = () => {
    if (!reverseScrollContainerRef.current) return;

    interval2.current = setInterval(() => {
      const container = reverseScrollContainerRef.current;
      if (!container) return; // Double-check inside setInterval

      if (container.scrollLeft <= 0) {
        container.scrollLeft = container.scrollWidth / 2;
      } else {
        container.scrollLeft -= scrollSpeed;
      }
    }, 30);
  };

  const stopScrollingRow1 = () => clearInterval(interval1.current);
  const stopScrollingRow2 = () => clearInterval(interval2.current);

  useEffect(() => {
    startScrollingRow1();
    startScrollingRow2();
    return () => {
      stopScrollingRow1();
      stopScrollingRow2();
    };
  }, []);

  // Separate hover handlers for each row
  const handleMouseEnterRow1 = () => stopScrollingRow1();
  const handleMouseLeaveRow1 = () => {
    stopScrollingRow1();
    startScrollingRow1();
  };

  const handleMouseEnterRow2 = () => stopScrollingRow2();
  const handleMouseLeaveRow2 = () => {
    stopScrollingRow2();
    startScrollingRow2();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isPlaying, setIsPlaying] = useState(false);
  const gameRef = useRef(null);

  // Function to handle entering fullscreen and starting the game
  const handlePlay = () => {
    setIsPlaying(true);

    setTimeout(() => {
      if (gameRef.current) {
        if (gameRef.current.requestFullscreen) {
          gameRef.current.requestFullscreen();
        } else if (gameRef.current.mozRequestFullScreen) {
          gameRef.current.mozRequestFullScreen();
        } else if (gameRef.current.webkitRequestFullscreen) {
          gameRef.current.webkitRequestFullscreen();
        } else if (gameRef.current.msRequestFullscreen) {
          gameRef.current.msRequestFullscreen();
        }
      }
    }, 100);
  };

  // Function to handle exiting fullscreen and stopping the game
  const handleExit = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      setIsPlaying(false);
    }
  };

  // Listen for fullscreen changes
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (
        !document.fullscreenElement &&
        !document.webkitFullscreenElement &&
        !document.mozFullScreenElement &&
        !document.msFullscreenElement
      ) {
        setIsPlaying(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const duplicatedBlogs12 = Array.from({ length: 6 }, () => blogs12).flat();
  const scrollRef2 = useRef(null);
  const [isUserScrolling2, setIsUserScrolling2] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsUserScrolling2(true);
      clearTimeout(window.scrollTimeout2);
      window.scrollTimeout2 = setTimeout(() => {
        setIsUserScrolling2(false); // Restart animation after user stops scrolling
      }, 3000);
    };

    const scrollContainer = scrollRef2.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => scrollContainer.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <>
      <div className="relative flex flex-col justify-center items-center bg-white mt-0 h-screen">
        <Helmet prioritizeSeoTags>
          <title>
            Cloudevolve | Transform cloud savings with Cloudevolve. Risk-free,
            commitment-free, and up to 60% AWS cost reduction. Smarter savings
            start here!
          </title>
          <link rel="Important" href="https://cloudevolve.com/home" />
          <meta
            name="description"
            content="Transform cloud savings with Cloudevolve. Risk-free, commitment-free, and up to 60% AWS cost reduction. Smarter savings start here!"
          />
          <meta
            name="keywords"
            content="Cloudevolve, cloud cost savings, cloud solutions, innovation, AWS cost management"
          />
          <meta name="author" content="Cloudevolve" />

          <meta property="og:image" content={Novagems} />
          <meta
            property="og:image:alt"
            content="Schedules, Appointment, Meeting"
          />
          <meta property="og:image" content={Lenskart} />
          <meta
            property="og:image:alt"
            content="Lenskart, Speds,Spectacles,Frames, Glasses ,lens Contact lens, Sun glasses,Opticals"
          />
          <meta property="og:image" content={Jubna} />
          <meta
            property="og:image:alt"
            content="Jubna for Advertisers, Advertise,publishers"
          />
          <meta property="og:image" content={Fitelo} />
          <meta
            property="og:image:alt"
            content="Fitelo ,Weight Loss ,Diet,Fat To Fit,Nutrition "
          />
          <meta property="og:image" content={Witzeal} />
          <meta
            property="og:image:alt"
            content="Witzeal,Gaming,Games,Bigcash ,Online Games"
          />
          <meta property="og:image" content={Outleap} />
          <meta
            property="og:image:alt"
            content="Outleap,studying abroad, Foreign Study, International Education, bon voyage"
          />
          <meta property="og:image" content={Steller} />
          <meta
            property="og:image:alt"
            content="Steller Data Recovery, data recovery, backup , data loss, hard drive recovery, ssd recovery, mobile recovery,laptop recovery, macbook recovery"
          />
          <meta property="og:image" content={People} />
          <meta
            property="og:image:alt"
            content="People, career and skills,Recruitment,Onboarding,Core HR,Payroll,Leave & Attendance,Performance,Learning,Succession Planning,Compensation"
          />

          <link rel="preload" as="video" href="/video.mp4" type="video/mp4" />
        </Helmet>

        <ResponsiveHero
          Hero={Hero}
          HeroMobile={HeroMobile}
          rightsidearrow={rightsidearrow}
          navigate={navigate}
        />
      </div>

      {/* First Section - Visible only on larger screens */}
      <div className="hidden sm:block mt-32 md:mt-20 lg:mt-20 2xl:mt-24">
        <h1 className="flex justify-center items-center mt-10 mb-4 font-semibold text-xl text-black">
          The Client Crew
        </h1>
        {/* Top row logos */}
        <div className="flex flex-wrap justify-center gap-8 items-center">
          <img
            src={Novagems}
            alt="Novagems Logo"
            loading="lazy"
            effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Lenskart}
            alt="Lenskart Logo"
            loading="lazy"
            effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Jubna}
            alt="Jubna Logo"
            loading="lazy"
            effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Fitelo}
            alt="Fitelo Logo"
            loading="lazy"
            effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Witzeal}
            alt="Witzeal Logo"
            loading="lazy"
            effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
        </div>

        {/* Bottom row logos */}
        <div className="flex flex-wrap justify-center gap-10 items-center mt-6">
          <img
            src={Outleap}
            alt="Outleap Logo"
            loading="lazy"
            effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={Steller}
            alt="Steller Logo"
            loading="lazy"
            effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={People}
            alt="People Logo"
            loading="lazy"
            effect="blur"
            className="w-[120px] object-contain h-auto grayscale hover:grayscale-0 transition-all duration-300"
          />
        </div>
      </div>

      {/* Second Section - Scrolling animation, visible on small screens only */}
      <div className="overflow-hidden mt-20 block md:hidden">
        <h1 className="flex justify-center  font-jakarta items-center mt-0 mb-4 font-bold text-lg ">
          The Client Crew
        </h1>
        <div className="flex gap-4 animate-scroll items-center">
          {[
            Novagems,
            Lenskart,
            Jubna,
            Fitelo,
            Witzeal,
            Outleap,
            Steller,
            People,
          ].map((logo, index) => (
            <img
              key={`logo-${index}`}
              src={logo}
              loading="lazy"
              effect="blur"
              alt={`Client Logo ${index + 1}`}
              tabIndex={0} // Helps trigger focus on touch devices
              className="w-[150px] h-[80px] object-contain grayscale hover:grayscale-0 focus:grayscale-0 active:grayscale-0 transition-all duration-300"
            />
          ))}
        </div>
      </div>

      {/* Reduced margin above the home curve */}
      <div className="flex justify-center w-full">
        <img
          src={homeCurve}
          alt="homecurve"
          loading="lazy"
          effect="blur"
          className="w-full h-auto"
        />
      </div>

      <div
        className="text-center mt-0 pb-16"
        style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
      >
        <h3
          className="rubik text-4xl font-bold leading-[48px] text-center "
          style={{ color: "#000000" }}
        >
          From Problems To Possibilities
        </h3>
        <h3 className="mt-2 font-jakarta" text-black>
          Because every cloud problem deserves a Cloudevolve solution.
        </h3>

        {/* Scrolling Container */}
        <div className="overflow-hidden mt-10">
          {/* Row 1: Left to Right */}
          <div
            ref={scrollContainerRef}
            className="flex gap-12 overflow-x-auto scrollbar-hide flex-nowrap"
            onMouseEnter={handleMouseEnterRow1}
            onMouseLeave={handleMouseLeaveRow1}
          >
            {firstHalf.map((problem, index) => (
              <div
                key={`row1-${index}`}
                className="flip-card flex-shrink-0 w-[calc(100%/5 - 24px)] min-w-[250px] max-w-[300px]"
              >
                <div className="flip-card-inner">
                  <div className="flip-card-front flex items-center text-left px-4 py-2 bg-white rounded-lg shadow-md">
                    <div className="h-12 w-12 flex items-center justify-center rounded">
                      <img
                        src={problem.icon}
                        alt={problem.title}
                        loading="lazy"
                        className="w-12 h-12 object-contain"
                      />
                    </div>
                    <span className="ml-3 text-sm font-jakarta text-black font-semibold">
                      {problem.title}
                    </span>
                  </div>
                  <div
                    className="flip-card-back flex min-h-[5rem] w-full max-w-md items-start p-2 rounded-lg -ml-7 shadow-md"
                    style={{ backgroundColor: problem.color }}
                  >
                    <div className="flex-shrink-0 w-16 h-16 mt-2 flex items-center justify-center">
                      <img
                        src={problem.icon}
                        alt={problem.title}
                        loading="lazy"
                        className="w-12 h-12 object-contain"
                      />
                    </div>
                    <div className="text-left ml-3 mt-3">
                      <span className="text-sm leading-relaxed font-jakarta text-black font-semibold">
                        {problem.solution}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Row 2: Right to Left */}
          <div
            ref={reverseScrollContainerRef}
            className="flex gap-12 overflow-x-auto scrollbar-hide flex-nowrap mt-6"
            onMouseEnter={handleMouseEnterRow2}
            onMouseLeave={handleMouseLeaveRow2}
          >
            {secondHalf.map((problem, index) => (
              <div
                key={`row2-${index}`}
                className="flip-card flex-shrink-0 w-[calc(100%/5 - 24px)] min-w-[250px] max-w-[300px]"
              >
                <div className="flip-card-inner">
                  <div className="flip-card-front flex items-center text-left px-4 py-2 bg-white rounded-lg shadow-md">
                    <div className="h-12 w-12 flex items-center justify-center rounded">
                      <img
                        src={problem.icon}
                        alt={problem.title}
                        loading="lazy"
                        className="w-12 h-12 object-contain"
                      />
                    </div>
                    <span className="ml-3 text-sm font-jakarta text-black font-semibold">
                      {problem.title}
                    </span>
                  </div>
                  <div
                    className="flip-card-back flex min-h-[5rem] w-full max-w-md items-start p-2 rounded-lg shadow-md -ml-7"
                    style={{ backgroundColor: problem.color }}
                  >
                    <div className="flex-shrink-0 w-16 h-16 mt-2 flex items-center justify-center">
                      <img
                        src={problem.icon}
                        alt={problem.title}
                        loading="lazy"
                        className="w-12 h-12 object-contain"
                      />
                    </div>
                    <div className="text-left ml-3 mt-3">
                      <span className="text-sm leading-relaxed font-jakarta text-black font-semibold">
                        {problem.solution}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="text-center mt-[60px] mb-[120px] pb-5">
        <h3 className="text-[40px] font-bold leading-[48px] tracking-[0%] text-center font-rubik text-black">
          Why Cloudevolve?
        </h3>

        <h3 className="font-normal mt-2 font-jakarta text-black">
          The smarter way to save, secure, and streamline your cloud experience.
        </h3>

        <div className="relative flex flex-col justify-center items-center">
          {/* Horizontal Line */}
          <div className="absolute lg:top-60 lg:ml-48 md:top-60 md:ml-12 lg:left-0 md:left-0 lg:w-[700px] md:w-[700px] xl:ml-80 lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4">
            {" "}
          </div>
          <div className="absolute lg:top-[520px] lg:ml-48 md:top-[520px] md:ml-12 lg:left-0 md:left-0 lg:w-[700px] md:w-[700px] xl:ml-80 lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4">
            {" "}
          </div>
          {/* Vertical Line */}
          <div className="absolute lg:left-1/2 md:left-1/2 lg:top-10 md:top-5 lg:h-[660px] md:h-[680px] w-0.5 bg-gray-25">
            {" "}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-40 gap-y-20 mt-12 px-4 lg:px-40">
            {cardData.map((card, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center w-full"
              >
                <div className="h-16 w-16 flex items-center justify-center rounded">
                  <img
                    src={card.icon}
                    alt={card.alt}
                    loading="lazy"
                    effect="blur"
                    className="w-14 h-14"
                  />
                </div>
                <h4 className="text-lg font-medium mt-4 rubik">
                  {" "}
                  {card.title}{" "}
                </h4>
                <p className="text-base font-jakarta mt-2 text-black max-w-[280px]">
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Light-colored straight line box shadow */}
      <div class="w-full h-px bg-white shadow-[0px_2px_4px_rgba(0,0,0,0.1)] -mt-12"></div>

      <div className="text-center mt-[70px] pb-5">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h3 className="rubik text-4xl font-bold leading-[48px] text-center">
            Platform
          </h3>

          <h3 className="mt-2 mb-6 font-jakarta text-black">
            Welcome to a smarter way to save and manage your AWS spend
          </h3>

          {/* Cards Container */}
          <div className="flex-col sm:flex-col lg:flex-row md:flex-row gap-6 text-left">
            {/* First Card - Increased width for Cloud 360 */}
            <div className="bg-white lg:col-span-6 flex justify-center">
              <div className="pl-6 py-2 grid grid-row-2 md:grid-cols-2 lg:grid-cols-2 items-center rounded-3xl border-[1.5px] border-gray-900 lg:w-[1000px]">
                {/* Adjust width as necessary */}
                {/* Text Content for Cloud 360 */}
                <div>
                  <h2 className="text-xl font-bold mb-4 rubik">Cloud 360</h2>
                  <p className="mb-4 font-jakarta">
                    Gain 360-degree visibility of your Cloud. Your all-in-one
                    intelligence platform for complete AWS cost visibility,
                    real-time analytics, and seamless tagging. Gain control,
                    optimize spend, and make smarter cloud decisions
                    effortlessly.
                  </p>
                  <div className="flex flex-wrap gap-2 mb-4 font-jakarta">
                    <span className="bg-[#D7FFEE] text-sm py-1 px-3 rounded">
                      Cloud Analytics
                    </span>
                    <span className="bg-[#D7FFEE] text-sm py-1 px-3 rounded">
                      Budget Alert
                    </span>
                    <span className="bg-[#D7FFEE] text-sm py-1 px-3 rounded">
                      Tagger
                    </span>
                    <span className="bg-[#D7FFEE] text-sm py-1 px-3 rounded">
                      Conformity
                    </span>
                    <span className="bg-[#D7FFEE] text-sm py-1 px-3 rounded">
                      Stop Idle Resources
                    </span>
                    <span className="bg-[#D7FFEE] text-sm py-1 px-3 rounded">
                      Cost Fixer
                    </span>
                  </div>
                  <button
                    className="bg-white border pl-2 pr-1 py-2 font-jakarta text-sm rounded-lg flex items-center gap-1 hover:text-white hover:bg-black group"
                    onClick={() => navigate("/platform/Cloud360")}
                  >
                    Learn more
                    <img
                      src={uparrowicon}
                      alt="Arrow Icon"
                      loading="lazy"
                      effect="blur"
                      className="w-5 h-5 transition duration-75 ease-in-out group-hover:invert group-hover:brightness-90"
                    />
                  </button>
                </div>
                {/* Image Content for Cloud 360 */}
                <div className="w-full lg:w-auto flex justify-center lg:justify-end mt-4 lg:mt-0">
                  <Lottie
                    animationData={data}
                    className="h-[300px] object-contain" // Set a fixed height
                    aria-labelledby="use lottie animation"
                  />
                </div>
              </div>
            </div>

            {/* Cards for Savings Warehouse and Savings CoPilot with equal widths */}

            <div className="flex flex-col gap-6 lg:flex-row md:flex-row justify-center items-center gap-3 mt-6 sm:ml-0">
              {/* Savings Warehouse */}
              <div className="bg-white flex justify-center">
                <div className="rounded-3xl border-[1.5px] border-gray-900 pt-6 pl-6 pr-6 sm:w-[350px] lg:w-[490px] lg:h-[650px] md:w-[350px] md:h-[600px] sm:min-h-[600px] flex flex-col justify-between overflow-hidden relative">
                  <div>
                    <h2 className="text-xl font-bold mb-4 rubik">
                      Savings Warehouse
                    </h2>
                    <p className="mb-4 font-jakarta">
                      No hassle of purchasing Savings plans, giving a commitment
                      to AWS. You get direct discounts without giving a
                      commitment of consumption.
                    </p>

                    <div className="flex flex-wrap gap-2 mb-4 font-jakarta">
                      {[
                        "EC2",
                        "SageMaker",
                        "Redshift",
                        "S3",
                        "EC2 Data Transfer",
                        "ECS",
                        "RDS",
                        "OpenSearch",
                      ].map((item) => (
                        <span
                          key={item}
                          className="bg-[#F2EDFE] text-sm py-1 px-3 rounded"
                        >
                          {item}
                        </span>
                      ))}
                    </div>

                    <button
                      className="bg-white font-jakarta border pl-2 pr-1 py-2 rounded-lg text-sm flex items-center gap-1 hover:text-white hover:bg-black group"
                      onClick={() => navigate("/platform/warehouse")}
                    >
                      Learn more
                      <img
                        src={uparrowicon}
                        alt="Arrow Icon"
                        loading="lazy"
                        className="w-5 h-5 transition duration-75 ease-in-out group-hover:invert group-hover:brightness-90"
                      />
                    </button>
                  </div>

                  {/* Adjust Video Section */}
                  <div className="relative flex items-end mt-4 mb-[-8px]">
                    <img
                      src={Savings}
                      loading="lazy"
                      alt="gif"
                      preload="metadata"
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              </div>

              {/* Savings CoPilot */}
              <div className="bg-white flex justify-center">
                <div className="rounded-3xl border-[1.5px] border-gray-900 pt-6 pl-6 pr-6 sm:w-[350px] lg:w-[490px] lg:h-[650px] md:w-[350px] md:h-[600px] flex flex-col">
                  {/* Content at the top */}
                  <div>
                    <h2 className="text-xl rubik font-bold mb-4">
                      Savings CoPilot
                    </h2>
                    <p className="mb-4 font-jakarta">
                      Your smart FinOps ally is coming soon to navigate cloud
                      costs with precision - no turbulence, just seamless
                      savings.
                    </p>
                    <div className="flex flex-wrap gap-2 mb-4  font-jakarta">
                      <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">
                        FinOpsSolutions
                      </span>
                      <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">
                        SavingsOnAutopilot
                      </span>
                      <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">
                        SeamlessSavings
                      </span>
                      <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">
                        OptimizeCloudSpend
                      </span>
                    </div>
                  </div>

                  <div className="mt-auto relative w-full h-80 overflow-hidden rounded-3xl flex justify-center items-center">
                    <img
                      key={Date.now()}
                      src={copilot}
                      loading="lazy"
                      alt="copilit"
                      preload="metadata"
                      className="w-96 h-80 object-contain"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center pt-5 mt-14 bg-[#02AAE2] text-[#FFFFFF]">
        <div className="max-w-7xl mx-auto px-4 mt-20 sm:px-6 lg:px-8 text-center">
          {/* <img
            src={price}
            alt="Price Icon"
            className="h-16 w-16 lg:h-16 lg:w-16 lg:ml-44"
          /> */}
          <h3 className="text-4xl font-bold flex justify-center items-center gap-2 text-center">
            <span className="rubik text-xl sm:text-2xl md:text-4xl font-bold leading-snug flex items-center gap-2 whitespace-nowrap">
              We are doing it risk-
              <span className="relative text-[#00FF8F] inline-block">
                FREE
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-0 w-full">
                  <img
                    src={Line}
                    alt="underline"
                    loading="lazy"
                    effect="blur"
                    className="w-[97%] sm:w-2/3 md:w-full max-w-[120px] h-auto mx-auto"
                  />
                </div>
              </span>
              &nbsp;for you
            </span>
          </h3>

          <h3 className="mt-5 font-jakarta mb-6 max-w-xl mx-auto text-center">
            Our goal is to ensure you only pay for what you use, without the
            burden of long-term commitments or hidden fees.
          </h3>

          <div className="flex justify-center mt-5 items-center gap-8">
            <button
              className="bg-white font-jakarta text-sm px-4 py-2 ml-10 lg:ml-10 md:ml-[40px] rounded-lg font-semibold flex items-center gap-1 shadow-md"
              style={{
                transition: "background-color 0.3s",
                color: "black",
              }}
              onClick={() => navigate("/contactus")}
            >
              <span>Get in touch</span>
              {/* <img src={arrowblc} className="w-5 h-5" alt="Arrow Icon" /> */}
            </button>
            <div className="flex  ">
              {/* <img
                src={prc2}
                alt="Price Icon"
                className="h-10 w-10 lg:h-16 lg:w-16 md:h-16 md:w-16 lg:ml-56 md:ml-44"
              /> */}
            </div>
          </div>

          <section className="mt-6">
            <h2 className="text-center text-xl font-medium mb-8 rubik">
              What Makes Our Pricing Stand Out?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
              {/* Feature 1 */}
              <div className="text-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  loading="lazy"
                  effect="blur"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="rubik font-bold text-lg mt-4 mb-2">
                  No Commitments, <br />
                  No Risk
                </h3>
                <p className="font-jakarta font-normal">
                  Enjoy savings without long-term contracts or upfront
                  investments.
                </p>
              </div>
              {/* Feature 2 */}
              <div className="text-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  loading="lazy"
                  effect="blur"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="rubik font-bold text-lg mt-4 mb-2">
                  Save Up to <br />
                  60% Instantly
                </h3>
                <p className="font-jakarta font-normal">
                  Reduce your cloud expenses without purchasing savings plans.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="text-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  loading="lazy"
                  effect="blur"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="rubik font-bold text-lg mt-4 mb-2">
                  Direct Discounts on <br />
                  AWS Services
                </h3>
                <p className="font-jakarta font-normal">
                  Access EC2, RDS, S3, and more with built-in discounts.
                </p>
              </div>
            </div>

            <br />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 max-w-3xl mx-auto mt-5">
              {/* Feature 4 */}
              <div className="text-center lg:place-self-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  loading="lazy"
                  effect="blur"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="rubik font-bold text-lg mt-4 mb-2">
                  Group Buying <br />
                  Power
                </h3>
                <p className="font-jakarta font-normal">
                  Leverage community-driven savings for<br></br> the best rates.
                </p>
              </div>
              {/* Feature 5 */}
              <div className="text-center lg:place-self-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  loading="lazy"
                  effect="blur"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="rubik font-bold text-lg mt-4 mb-2">
                  Flexible <br />
                  Plans
                </h3>
                <p className="font-jakarta font-normal">
                  Adapt to changes in cloud consumption<br></br> without
                  penalties.
                </p>
              </div>
            </div>
          </section>
        </div>

        <div className="flex justify-center bg-transparent">
          <img
            src={Vector624}
            alt="Curve Background"
            loading="lazy"
            effect="blur"
            className="lg:w-full md:w-full w-full -mb-1 md:mb-0" // Adds bottom margin only for mobile screens
          />
        </div>
      </div>

      <div className="flex justify-center items-center w-full mt-20">
        <h2 className="text-center font-semibold text-2xl sm:text-3xl md:text-4xl leading-snug tracking-normal max-w-5xl rubik mb-5">
          Game On: Boost Your Cloud Savings with Every Move!
        </h2>
      </div>
      {/* <div className="text-center font-jakarta font-normal text-base sm:text-lg md:text-xl   ">
        <p>Welcome to a smarter way to save and manage your AWS spend</p>
      </div>  */}
      <p className="block lg:hidden text-left font-[Rubik] font-medium italic text-[13px] text-[#F44336] ">
        *This game will only work on desktop
      </p>
      <div>
        <img
          key={Date.now()}
          src={Gamemobile}
          alt="game"
          className="block md:hidden w-full h-auto"
          loading="lazy"
        />
      </div>
      {!isPlaying && <Navbar />}
      <div className="relative w-full px-44 rounded-2xl overflow-hidden">
        {/* Video Background */}
        <div className="relative w-full">
          <video
            src={Gamedesktop}
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            className="hidden md:block w-full h-auto rounded-3xl object-cover"
          />

          {/* Play Button */}
          {!isPlaying && (
            <button
              className="hidden md:flex absolute bottom-4 right-4 md:bottom-8 md:right-8 lg:bottom-10 lg:right-10
                px-3 py-1 md:px-3 md:py-2 text-sm md:text-base font-bold 
                border border-[#C6C6C6] bg-[#FFF5BF7A] text-black rounded-3xl shadow-lg 
                hover:bg-gray-500 items-center space-x-2 z-10"
              onClick={handlePlay}
            >
              <span>Play</span>
              <img
                src={Play}
                alt="Play Icon"
                className="w-6 h-6 md:w-4 md:h-4"
              />
            </button>
          )}
        </div>

        {/* Game Component in Fullscreen */}
        {isPlaying && (
          <div
            ref={gameRef}
            className="bg-[#81d5f1] w-full h-screen fixed top-0 left-0 z-[9999] overflow-hidden"
          >
            <Game onExit={handleExit} />
          </div>
        )}
      </div>

      <div
        className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-12 text-left"
        style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
      >
        {/* Security Section Card */}
        <div className="bg-white lg:col-span-2 flex justify-center lg:mb-12 lg:mt-12">
          <div className="w-full max-w-xl  grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 items-center gap-6">
            {/* Image Content for Security */}
            <div className="order-1 lg:order-1 flex justify-evenly items-center lg:justify-start h-full">
              <video
                src={Secure}
                autoPlay
                loading="lazy"
                effect="blur"
                loop
                muted
                playsInline
                webkit-playsinline="true"
                preload="metadata"
                className="w-full transform lg:translate-x-[-30%]" // Apply the shift only for laptop screens
              />
            </div>

            {/* Text Content for Security */}
            <div className="order-2 lg:order-2 lg:ml-0 ">
              <h2 className="rubik text-2xl md:text-3xl lg:text-3xl font-bold leading-none text-center lg:text-left whitespace-nowrap">
                How secure are we?
              </h2>
              <p className="mb-4 mt-3 font-jakarta text-center lg:text-left lg:w-[390px] mx-auto">
                From cost management to architectural guidance, we're here to
                ensure your cloud experience is seamless and optimized.
              </p>

              <div className="flex flex-wrap gap-2 mt-2 mb-12 font-jakarta justify-center lg:justify-start">
                <div className="flex flex-col lg:flex-row gap-2 justify-center items-center lg:items-start">
                  <span className="bg-[#FFF8F1] text-sm py-1 px-3 rounded w-full lg:w-44 text-center lg:text-left">
                    Continuous Monitoring
                  </span>
                  <span className="bg-[#FFF8F1] text-sm py-1 px-3 rounded w-full lg:w-60 text-center lg:text-left">
                    Automated Threat Responses
                  </span>
                </div>
                <div className="flex flex-col lg:flex-row gap-2 justify-center items-center lg:items-start">
                  <span className="bg-[#FFF8F1] text-sm py-1 px-3 rounded w-full lg:w-40 text-center lg:text-left">
                    Anomaly Detection
                  </span>
                  <span className="bg-[#FFF8F1] text-sm py-1 px-3 rounded w-full lg:w-64 text-center lg:text-left">
                    Safeguard Against Vulnerabilities
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-[70px] mb-0 pb-5">
        <h3 className="rubik text-4xl font-bold leading-[48px] text-center">
          Testimonials
        </h3>
        <h3 className="font-jakarta mt-2 text-black">
          Hear it from those who save big with us.
        </h3>

        <div
          ref={scrollRef2}
          className="overflow-x-auto whitespace-normal mt-14 w-full"
          style={{
            scrollBehavior: "smooth",
            msOverflowStyle: "none" /* IE and Edge */,
            scrollbarWidth: "none" /* Firefox */,
            WebkitOverflowScrolling: "touch" /* Better touch scrolling */,
            scrollSnapType: "x mandatory" /* Smooth card alignment */,
            willChange: "transform",
          }}
        >
          <style jsx>{`
            div::-webkit-scrollbar {
              display: none; /* Chrome, Safari, Opera */
            }
          `}</style>
          <div
            className={`flex gap-4 ${
              !isUserScrolling2 ? "animate-infinite-scroll" : ""
            }`}
          >
            {duplicatedBlogs12.map((blog, index) => (
              <div
                key={index}
                className="w-[280px] left-0 h-[360px] lg:w-[90%] lg:h-[320px] md:w-[70%] md:h-[350px] flex-shrink-0 max-w-2xl p-2 grid md:grid-cols-2 lg:grid-cols-2 items-stretch rounded-2xl shadow-lg mb-5"
                style={{
                  flex: "0 0 auto",
                  backgroundColor: "#ffff",
                  border: "1px solid black",
                  backgroundImage: `url(${blg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="flex flex-col justify-between p-1">
                  <p className="text-lg md:text-lg lg:text-xl mb-4 text-left rubik font-normal ml-4 lg:ml-5 lg:mt-5 md:ml-5 md:mt-5">
                    {blog.content}
                  </p>
                  <div className="flex">
                    <div className="flex justify-start items-center md:hidden lg:hidden">
                      <img
                        src={blog.image}
                        alt={blog.author}
                        className="w-24 h-24 ml-4 object-contain block sm:hidden"
                      />
                    </div>
                    <div>
                      <h3 className="flex justify-start ml-12 mt-5 md:justify-end lg:justify-end rubik font-semibold text-lg text-left md:text-left lg:text-left md:text-base lg:text-base lg:ml-2 md:ml-4 lg:mr-20 md:mr-8 lg:mb-5 md:mb-5">
                        - {blog.author}, {blog.role}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center">
                  <img
                    src={blog.image}
                    alt={blog.author}
                    className="w-64 h-64 hidden md:flex lg:flex"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="relative w-full flex justify-center sm-max:w-full xl:h-[400px] mt-20 mx-auto m-5 lg:h-42 md:h-42  sm:p-0">
        {/* Group1 Image for non-mobile screens */}
        <img
          src={Group1}
          alt="Group"
          loading="lazy"
          effect="blur"
          className="hidden sm:block border-t-2 border-gray-900 lg:w-full md:w-full lg:h-auto md:h-auto sm:rounded-none h-[400px] object-cover"
        />

        {/* Group1 Alternative Image for mobile screens */}
        <img
          src={Group2}
          alt="Mobile Group"
          loading="lazy"
          effect="blur"
          className="block sm:w-full sm:hidden lg:w-full md:w-full lg:h-auto md:h-auto rounded-lg h-[400px] object-cover"
        />

        {/* Hand GIF 1 */}
        <div className="absolute right-0 w-24 h-24 md:w-30 md:h-30 mb-32 mt-5 lg:mb-44 mr-28 mobile-m:mr-36 mobile-l:mr-40 lg:hidden md:hidden">
          <img
            src={Hand}
            alt="Hand GIF"
            loading="lazy"
            effect="blur"
            className="w-full h-full"
          />
        </div>

        {/* Hand GIF 2 */}
        <div className="absolute bottom-0 right-0 md:mr-5 md:mb-[45px] lg:mb-[30px] lg:w-64 lg:h-72 xl:mb-[55px] xl:h-80 xl:w-[330px] xl:mr-5 w-24 h-24 md:w-44 md:h-48 mb-32 mr-14 hidden md:flex">
          <img
            src={Hand}
            alt="Hand GIF"
            loading="lazy"
            effect="blur"
            className="w-full h-full"
          />
        </div>

        {/* Vector624 Image */}
        <img
          src={Cloud}
          alt="Vector"
          loading="lazy"
          effect="blur"
          className="absolute w-full h-auto bottom-0 md:-bottom-0.5 lg:-bottom-1.5 left-0 xl:h-44  md:h-28 lg:h-32 px-3 sm:p-0 hidden sm:block"
        />

        {/* Border below vector image */}
        <div className="absolute bottom-0 left-0 w-full sm:h-4 h-2  rounded-lg"></div>

        <div className="absolute inset-0 flex flex-col items-center sm:items-start sm:justify-center px-4 lg:py-10 md:px-12 mb-20">
          <h3 className="font-bold rubik text-xl mt-32 sm:mt-0 ml-2 sm:ml-0 lg:ml-32 md:ml-0 xl:text-4xl lg:text-3xl md:text-2xl text-white mb-2 sm:p-0 m-0 text-center sm:text-left">
            Are you not ready to commit?
          </h3>
          <p className="font-normal font-jakarta ml-0 sm:ml-10 mr-0 sm:mr-12 lg:ml-32 md:ml-0 lg:mt-0 md:mt-0 text-base md:text-lg text-white mb-4 text-center sm:text-left w-10/12 sm:w-auto mx-auto">
            Step into the cloud with us - no pressure, just perks!
          </p>

          <div className="ml-0 sm:ml-24 md:ml-0 lg:ml-32 xl:ml-32">
            <button
              className="flex items-center bg-white px-4 py-2 md:px-2 md:py-2 rounded-lg border border-horizonOrange-950 hover:bg-gray-200"
              onClick={() => navigate("/contactus")}
            >
              <span className="text-black font-jakarta text-start text-xs font-semibold md:text-base lg:text-base w-20 md:w-auto lg:w-auto text-center sm:text-left">
                Get in touch
              </span>
              {/* <img
                src={Icon1}
                alt="Icon"
                className="w-3 h-3 md:w-4 md:h-4 lg:w-5 lg:h-5 ml-2"
              /> */}
            </button>
          </div>
        </div>
      </div>

      {/* Blogs Section */}
      <div
        className="text-center mt-12 mb-8 pb-5"
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <h3
          className="rubik text-4xl font-bold leading-[48px] text-center"
          style={{ color: "#000000" }}
        >
          Blogs
        </h3>
        <h3 className="mt-2 mb-8 font-jakarta" style={{ color: "#222222" }}>
          Your go-to guide for cloud savings and insights
        </h3>
        <div
          className="scroll-container overflow-hidden"
          style={{ width: "100%", position: "relative" }}
        >
          <div className="scrolling-content flex">
            {[...blogs, ...blogs].map((blog, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl border border-black mx-4 blog-card"
                style={{
                  width: "20rem",
                  overflow: "hidden",
                  display: "inline-block",
                  boxSizing: "border-box",
                  flexShrink: 0, // Prevent shrinking
                }}
              >
                <img
                  src={blog.image}
                  alt={blog.title}
                  loading="lazy"
                  effect="blur"
                  className="rounded-t-2xl object-cover"
                  style={{
                    width: "100%",
                    height: "15rem",
                    objectFit: "cover",
                  }}
                />
                <div className="pt-5 pl-5 pr-5 pb-4 text-left">
                  <p className="text-gray-500 text-sm font-jakarta">
                    {blog.date}
                  </p>
                  <h3
                    className="mt-1 text-sm font-jakarta"
                    style={{ lineHeight: "1.5" }}
                  >
                    {blog.title}
                  </h3>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {blog.tags.map((tag, tagIndex) => (
                      <span
                        key={tagIndex}
                        className="px-2 py-2 text-xs rounded font-jakarta"
                        style={{
                          backgroundColor: "#E1F5FC",
                          color: "#222222",
                        }}
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="pt-12 px-2 bg-white">
        <h2 className="rubik text-4xl font-bold leading-[48px] text-center mb-12">
          Frequently Asked Questions
        </h2>
        <div
          className="max-w-4xl mx-auto space-y-4 group border "
          style={{
            border: "1px solid black",
            borderRadius: "25px",
            padding: "20px",
          }}
        >
          {/* FAQ 1 */}
          <div>
            {faqs.map((faq, index) => (
              <div key={index} className="border-b py-4">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFaq(index)}
                >
                  <h2 className="font-bold font-jakarta">{faq.question}</h2>
                  <span>
                    {openIndex === index ? (
                      <img
                        src={xmark}
                        alt="Close"
                        loading="lazy"
                        effect="blur"
                        className="w-5 h-5"
                      />
                    ) : (
                      <img
                        src={plus}
                        alt="Open"
                        loading="lazy"
                        effect="blur"
                        className="w-5 h-5"
                      />
                    )}
                  </span>
                </div>
                {openIndex === index && (
                  <p className="mt-2 font-jakarta">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
