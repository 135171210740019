import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Warehouse from './Platform/warehouse';
import Home from './Homepage/Home';
import PricingPage from './Pricing/pricing';
import ContactForm from './Contactus/Contactus';
import Aboutus from './Aboutus/Aboutus';
import Cloud360 from './Cloud360/Cloud360';
import Cloudmanagedservices from './cloudmanagedservices/Cloudmanagedservices';
import Footer from './Footer/footer';

import Navbar from './Common/Navbar';
import Bookyourdate from './Common/Bookyourdate';
import Testimonials from './Testimonials/Testimonials';
import Blogs from './Resources/Blogs';
import Faq from './Resources/Faq';
import { HelmetProvider } from 'react-helmet-async'; 
import CaseStudiesPage from './Casestudy/CaseStudiesPage';
import CaseStudiesDetailPage from './CaseStudiesDetailPage/CaseStudiesDetailPage';
import PrivacyPolicy from './Privacy/PrivacyPolicy';
import GameContainer from './Game/GameContainer';


const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <nav>
          <Navbar />
        </nav>
        <Routes>
          {/* Redirect root to /home */}
          <Route path="/" element={<Navigate to="/home" replace />} />

          {/* Define all valid routes */}
          <Route path="/platform/warehouse" element={<Warehouse />} />
          <Route path="/platform/case-study" element={<CaseStudiesPage />} />
          <Route path="/platform/Case-Studies-Detail-Page" element={<CaseStudiesDetailPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/contactus" element={<ContactForm />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/platform/cloud360" element={<Cloud360 />} />
          <Route path="/cloudmanagedservices" element={<Cloudmanagedservices />} />
          <Route path="/bookyour" element={<Bookyourdate />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/GameContainer" element={<GameContainer/>}/>

          {/* Catch-all route: Redirect any other URL to /home */}
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
};

export default App;
